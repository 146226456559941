import DocumentLifecycleService, {DocumentLifecycleApiOperationError} from "@/services/DocumentLifecycleService";
// eslint-disable-line no-unused-vars
export default {
  startApprovalProcess({commit}, documentId) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.startApprovalProcess(documentId)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  stopApprovalProcess({commit}, documentId) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.stopApprovalProcess(documentId)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  startSigningProcess({commit}, payload) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.startSigningProcess(payload)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  stopSigningProcess({commit}, payload) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.stopSigningProcess(payload)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  finishSigningProcess({commit}, payload) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.finishSigningProcess(payload)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  completeManually({commit}, documentId) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.completeManually(documentId)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  archive({commit}, documentId) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.archive(documentId)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancel({commit}, documentId) {
    return new Promise((resolve, reject) => {
      DocumentLifecycleService.cancel(documentId)
        .then((response) => {
          if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
