import {ApiService} from "./ApiService";
import {DocumentsApiOperationError} from "@/services/DocumentService";

class TemplateCrudError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const TEMPLATES_API_PATH = '/templates'

const TemplateService = {
    handleErrorCases: (response) => {
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response
    },
    fetchAll: async () => {
        const response = await ApiService.get('/templates');
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response.data;
    },
    fetchQuickAccessList: async () => {
        const response = await ApiService.get('/templates/limited');
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response.data;
    },
    fetchVariables: async (templateId) => {
        const response = await ApiService.get(`${TEMPLATES_API_PATH}/${templateId}/variables`)
        TemplateService.handleErrorCases(response)
        return response.data
    },
    fetchById: async (templateId) => {
        const response = await ApiService.get('/templates/' + templateId);
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response.data;
    },
    deleteTemplate: async (templateId) => {
        const response = await ApiService.delete('/templates/' + templateId);
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response;
    },
    createFromDocument: async (request, config) => {
        const response = await ApiService.post('/templates/from-document', request, config);
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response.data;
    },
    createFromDocumentMultipart: async (request, config) => {
        const response = await ApiService.upload('/templates/from-document', request, config);
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response.data;
    },
    update: async (templateId, request) => {
        const response = await ApiService.put('/templates/' + templateId, request);
        if (response.data.code) {
            throw new TemplateCrudError(response.data.code, response.data.message);
        }
        return response;
    }
};

export default TemplateService;

export {
    TemplateCrudError
}