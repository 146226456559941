import {ApiService} from './ApiService'


class SectionCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class SectionStateChangeError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class SectionCommentsError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class SectionVersionsError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}


class SectionApproversError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class SectionReviewError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class SectionsByGroupsError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const SECTIONS_API_PATH = '/sections'

const SectionService = {
  create: async (documentId, request) => {
    return await ApiService.post(`/documents/${documentId}${SECTIONS_API_PATH}`, request)
  },

  edit: async (sectionId, request) => {
    return await ApiService.put(`${SECTIONS_API_PATH}/${sectionId}`, request)
  },

  updateHeader: async (sectionId, request) => {
    return await ApiService.put(`${SECTIONS_API_PATH}/${sectionId}/header`, request)
  },

  editMany: async (documentId, request) => {
    return await ApiService.put(`/documents/${documentId}/sections`, request)
  },

  delete: async (sectionId) => {
    return await ApiService.delete(`${SECTIONS_API_PATH}/${sectionId}`);
  },

  reorder: async (documentId, sectionIdList) => {
    let uri = `/documents/${documentId}${SECTIONS_API_PATH}/reorder`
    return await ApiService.put(uri, sectionIdList)
  },


  fetchOne: async (sectionId) => {
    const response = await ApiService.get(`${SECTIONS_API_PATH}/${sectionId}`)
    if (response.data.code) {
      throw new SectionCrudError(response.data.code, response.data.message)
    }
    return response;
  },


  fetchAll: async (documentId, groupId) => {
    let uri = `/documents/${documentId}${SECTIONS_API_PATH}`
    if (groupId) {
      uri = uri.concat(`?groupId=${groupId}`)
    }
    return await ApiService.get(uri)
  },

  approve: async (documentId, sectionId) => {
    return await ApiService.put(`/documents/${documentId}/sections/${sectionId}/approve`, {})
  },

  reject: async (payload) => {
    return await ApiService.put(`/documents/${payload.documentId}/sections/${payload.sectionId}/reject`, payload.request)
  },

  addComment: async (sectionId, request) => {
    const response = await ApiService.post(`${SECTIONS_API_PATH}/${sectionId}/comments`, request)
    if (response.data.code) {
      throw new SectionCommentsError(response.data.code, response.data.message)
    }
    return response;
  },

  editComment: async (sectionId, commentId, request) => {
    const response = await ApiService.put(`${SECTIONS_API_PATH}/${sectionId}/comments/${commentId}`, request)
    if (response.data.code) {
      throw new SectionCommentsError(response.data.code, response.data.message)
    }
    return response;
  },

  deleteComment: async (sectionId, commentId) => {
    const response = await ApiService.delete(`${SECTIONS_API_PATH}/${sectionId}/comments/${commentId}`)
    if (response.data.code) {
      throw new SectionCommentsError(response.data.code, response.data.message)
    }
    return response;
  },

  resolveComment: async (sectionId, commentId) => {
    const response = await ApiService.put(`${SECTIONS_API_PATH}/${sectionId}/comments/${commentId}`, {})
    if (response.data.code) {
      throw new SectionCommentsError(response.data.code, response.data.message)
    }
    return response;
  },

  fetchAllComments: async (sectionId) => {
    const response = await ApiService.get(`${SECTIONS_API_PATH}/${sectionId}/comments`)
    if (response.data.code) {
      throw new SectionCommentsError(response.data.code, response.data.message)
    }
    return response;
  },

  fetchAllVersionsBySection: async (sectionId) => {
    const response = await ApiService.get(`${SECTIONS_API_PATH}/${sectionId}/versions`)
    if (response.data.code) {
      throw new SectionVersionsError(response.data.code, response.data.message)
    }
    return response;
  },

  fetchAllDocumentVersions: async (documentId) => {
    const response = await ApiService.get(`/documents/${documentId}/versions`)
    if (response.data.code) {
      throw new SectionVersionsError(response.data.code, response.data.message)
    }
    return response;
  },

  fetchDiff: async (sectionId, version) => {
    return await ApiService.get(`/sections/${sectionId}/diff/${version}`)
  },

  addApprover: async (sectionId, request) => {
    return ApiService.put(`${SECTIONS_API_PATH}/${sectionId}/approvers`, request)
  },

  removeApprover: async (sectionId, toBeRemovedGroupId) => {
    const response = await ApiService.delete(`${SECTIONS_API_PATH}/${sectionId}/approvers/${toBeRemovedGroupId}`)
    if (response.data.code) {
      throw new SectionApproversError(response.data.code, response.data.message)
    }
    return response;
  },

  fetchAllApprovers: async (sectionId) => {
    return await ApiService.get(`${SECTIONS_API_PATH}/${sectionId}/approvers`)
  },

  updateApproverRole: async (payload) => {
    let response = await ApiService.put(`${SECTIONS_API_PATH}/${payload.sectionId}/approvers`, payload.request);
    if (response.data.code) {
      throw new SectionApproversError(response.data.code, response.data.message)
    }
    return response;
  },

  fetchWaitingForReviews: async () => {
    const response = await ApiService.get(`${SECTIONS_API_PATH}/reviews/waiting`)
    if (response.data.code) {
      throw new SectionReviewError(response.data.code, response.data.message)
    }
    return response
  },

  fetchSectionsByGroups: async (documentId) => {
    const response = await ApiService.get(`documents/${documentId}${SECTIONS_API_PATH}/groups`)
    if (response.data.code) {
      throw new SectionsByGroupsError(response.data.code, response.data.message)
    }
    return response
  },

  redemandForApprove: async (payload) => {
    const response = await ApiService.put(`documents/${payload.documentId}/sections/${payload.sectionId}/approvers/${payload.approverId}/redemand`, {})
    if (response.data.code) {
      throw new SectionApproversError(response.data.code, response.data.message)
    }
    return response
  }
};

export default SectionService;

export {
  SectionApproversError,
  SectionVersionsError,
  SectionCommentsError,
  SectionStateChangeError,
  SectionCrudError
}

