/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/



// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  uid: 0,          // From Auth
  displayName: "John Doe", // From Auth
  about: "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL: "https://media-exp1.licdn.com/dms/image/C4E03AQEEBPxHD27E0w/profile-displayphoto-shrink_200_200/0/1575202593203?e=1618444800&v=beta&t=VG0pCiVBN8FznyVUoxgOtHMRYIn5lxmiGsV8ZayKuNA", // From Auth
  status: "online",
  userRole: "admin",
  teamName: ""
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  error: {
    hasError: false,
    errorCode: 0,
    errorMessage: null,
    detailedMessage: null
  }
}

export default state
