export default {
    SET_LOADING(state, loading) {
        state.listLoading = loading
    },
    DOCUMENT_VERSIONS_LOADING(state, loading) {
        state.sectionVersionsLoading = loading
    },
    SET_ERROR(state, error) {
        state.error = error
    },
    SET_VERSION_LIST(state, data) {
        state.versionList = data
    },
    ADD_SECTION_COMMENT_LIST(state, data) {
        state.commentList = data
    },
    SET_SECTION_APPROVERS(state, approvers) {
        state.sectionApprovers = approvers
    },
    SET_SECTION_ACCESS_LEVEL(state, accessLevel) {
        state.requesterAccessLevel = accessLevel
    },
    SET_SELECTED_VERSION(state, sectionVersion) {
        state.selectedVersion = sectionVersion
    },
    SET_SECOND_SELECTED_VERSION(state, sectionVersion) {
        state.secondSelectedVersion = sectionVersion
    },
    UPDATE_APPROVER_ROLE(state, payload) {
        state.sectionApprovers.forEach(sa => {
            if (sa.userId === payload.userId) {
                sa.accessLevel = payload.accessLevel
            }
        })
    },


}
