import state from './moduleSectionState'
import mutations from './moduleSectionMutations.js'
import actions from './moduleSectionActions.js'
import getters from './moduleSectionGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
