import router from '../../router'

export default {
  CREATE_SUCCESS(state, uid) {
    // save into cache
    router.push("/contracts/edit/" + uid)
  },
  SET_LOADING(state, loading) {
    state.listLoading = loading
  },
  SET_LOADING_VARIABLES(state, loading) {
    state.variablesLoading = loading
  },
  SET_DEFINED_VARIABLE_LIST(state, data) {
    state.variableList = data
  },
  LOAD_MY_DOCUMENTS_SUCCESS(state, list) {
    state.myDocumentList = list
  },
  LOAD_SHARED_DOCUMENTS_SUCCESS(state, list) {
    state.sharedDocumentList = list
  },
  REFRESH_DOCUMENT_STATE(state) {
    state.document = state.emptyDocument
  },
  PUT_ON_DOCUMENT(state, data) {
    state.document = data
  },
  ADD_CONTENT_HTML(state, data) {
    state.document.htmlContent = data
  },
  ADD_LABEL(state, label) {
    state.document.labels.push(label)
  },
  REMOVE_LABEL(state, label) {
    state.document.labels = state.document.labels.filter(l => l !== label)
  },

  CREATE_RULE_SUCCESS(state) {
  },

  RULE_LIST_LOADED(state, list) {
    state.ruleList = list
  },
  SET_DOCUMENT_TYPES(state, documentTypes) {
    state.documentTypes = documentTypes
  },
  SET_DOCUMENT_STATUSES(state, documentStatuses) {
    state.documentStatuses = documentStatuses
  }
};
