export default {

    getRuleList: state => {
        return state.ruleList
    },
    getDocumentTypes: state => {
        return state.documentTypes
    },
    getDocumentStatuses: state => {
        return state.documentStatuses
    },
    getSections: state => {
        return state.sections
    },
    getSectionById: state => id => {
        return state.sections.find(value => value.sectionId === id)
    },
    getSectionsUnsaved: state => {
        let unsavedSection = []
        // todo should be manage unsaved sections
        state.sections.forEach(sectionId => {
            unsavedSection.push(this.getSectionById(state, sectionId))
        })
        return unsavedSection
    },
    getVariables: state => {
        return state.variableList
    },
    getCurrentDocumentStatus: state => {
        return state.document.lifecycleDetail.status.name
    },
    getDocumentId: state => {
      return state.document.documentId
    },
    getSectionLastFocusedIndex: state => sectionId => {
        let index = null
        state.sections.forEach(section => {
            if (sectionId === section.sectionId) {
                console.log(section)
                index = section.lastFocusedIndex
            }
        })
        return index
    }
}
