import state from './moduleAttributeState.js'
import mutations from './moduleAttributeMutations.js'
import actions from './moduleAttributeActions.js'
import getters from './moduleAttributeGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}