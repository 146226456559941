export default {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_LOADING_VARIABLES(state, loading) {
    state.variablesLoading = loading
  },
  TEMPLATE_WAS_FETCHED_BY_ID(state, templates) {
    state.templates = templates;
  },
  TEMPLATE_VARIABLES_WERE_FETCHED(state, variables) {
    state.variableList = variables;
  },
  TEMPLATES_WERE_FETCHED(state, templates) {
    state.templates = templates;
  },
};
