import {ApiService} from "./ApiService";

class LibraryCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const LibraryDataPoolService = {
  fetchAll: async () => {
    const response = await ApiService.get('/library');

    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }

    return response.data;
  },
  fetchById: async (itemId) => {
    const response = await ApiService.get('/library/' + itemId);
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response.data;
  },
  deleteItem: async (itemId) => {
    const response = await ApiService.delete('/library/' + itemId);
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response;
  },
  saveTemplate: async (payload) => {
    const response = await ApiService.post('/library', payload);
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response.data;
  },
  updateLibraryItemTitle: async (payload, libraryItemId) => {
    const response = await ApiService.put('/library/' + libraryItemId, payload);
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response;
  },
  removeAttribute: async (itemId, attributeId) => {
    const response = await ApiService.delete('/library/' + itemId + '/Attributes/' + attributeId)
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response;
  },
  addAttribute: async (itemId, request) => {
    const response = await ApiService.post('/library/' + itemId + '/Attributes', request)
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response;
  },
  toggleRequired: async (itemId, attributeId, request) => {
    const response = await ApiService.put('/library/' + itemId + '/Attributes/' + attributeId, request)
    if (response.data.code) {
      throw new LibraryCrudError(response.data.code, response.data.message);
    }
    return response;
  }
};

export default LibraryDataPoolService;
