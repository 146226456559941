export default {
  contractId: null,
  authenticating: false,
  authenticationErrorCode: 0,
  authenticationError: "",
  myDocumentList: [],
  sharedDocumentList: [],
  listLoading: false,
  variablesLoading: false,
  variableList: [],
  templatesLoading: false,
  templateList: [],
  ruleList: [],
  document: {
    id: null,
    title: 'Adsız doküman',
    code: null,
    createdAt: null,
    status: null,
    templateId: null,
    attributes: null,
    rules: null,
    documentType: null,
    owner: {
      id: null,
      fullName: null
    },
    labels: [],
    groups: [],
    htmlContent: null,
  },
  emptyDocument:{
    id: null,
    title: 'Adsız doküman',
    code: null,
    createdAt: null,
    status: null,
    templateId: null,
    attributes: null,
    rules: null,
    documentType: null,
    owner: {
      id: null,
      fullName: null
    },
    labels: [],
    groups: []
  },
  documentTypes: [],
  documentStatuses: []
}
