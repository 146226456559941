import LabelService from '../../services/LabelService';

export default {
  async query({commit}, queryText) {
    const response = await LabelService.query(queryText)
    const labels = response.data.labels

    commit('LABELS_QUERIED', labels)
  },
  // async loadAll({commit}) {
  //   const response = await LabelService.loadAll()
  // },
  async addToDocument({commit}, payload) {
    await LabelService.addToDocument(payload.documentId, payload.request)
  },
  async removeLabel({commit}, payload) {
    await LabelService.remove(payload.documentId, payload.label)
  }
};
