import WorkspaceService from "../../services/WorkspaceService";
import {OrganizationEventTypes, PEventBus} from "@/services/PEventBus";

export default {
  async fetchOrganizations({commit}) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      WorkspaceService.fetchMyOrganizations()
        .then((response) => {
          if (response.data.code) {
            commit('SET_ERROR', response.data, {root: true})
          } else {
            commit('ORGANIZATIONS_FETCHED', response.data)
          }
          commit("SET_LOADING", false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          commit("SET_LOADING", false)
          reject(error)
        })
    });
  },

  async fetchById({commit}, organizationId) {
    try {
      let response = await WorkspaceService.fetchById(organizationId);
      commit('SELECTED_ORGANIZATION', response.data)
      return response.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async fetchGroups({commit}, workspaceId) {
    try {
      let response = await WorkspaceService.fetchGroups(workspaceId);
      commit('SET_WORKSPACE_GROUPS', response.data.groups)
      return response.data.groups
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async fetchMembers({commit}, organizationId) {
    try {
      let response = await WorkspaceService.fetchMembers(organizationId);
      commit('SET_ORGANIZATION_MEMBERS', response.data.members)
      return response.data.members
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async fetchInvitations({commit}, organizationId) {
    try {
      let response = await WorkspaceService.fetchInvitations(organizationId);
      commit('SET_INVITED_MEMBERS', response.data.users)
      return response.data.users
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async create({commit}, payload) {
    commit('SET_LOADING', true)
    // try {
    await WorkspaceService.create(payload.request)
    PEventBus.$emit(OrganizationEventTypes.ORGANIZATION_CREATED)
    // }catch (e) {
    //     return Promise.reject(e)
    // }
    commit('SET_LOADING', false)
  },

  async update({commit}, payload) {
    try {
      await WorkspaceService.update(payload.workspaceId, payload.request)
      PEventBus.$emit(OrganizationEventTypes.ORGANIZATION_UPDATED)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async delete({commit}, organizationId) {
    await WorkspaceService.delete(organizationId)
    PEventBus.$emit(OrganizationEventTypes.ORGANIZATION_DELETED)
  },

  async addUser({commit}, payload) {
    try {
      await WorkspaceService.addUser(payload.organizationId, payload.request)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async removeUser({commit}, payload) {
    try {
      await WorkspaceService.removeUser(payload.organizationId, payload.userId)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async invite({commit}, payload) {
    return await WorkspaceService.invite(payload.organizationId, payload.request)
      .then(response => response)
      .catch(err => err)
  },

  async setDefaultOrganization({commit}, organizationId) {
    const payload = {
      organizationId: organizationId
    }

    await WorkspaceService.setDefaultOrganization(payload)
      .then(res => PEventBus.$emit(OrganizationEventTypes.DEFAULT_ORGANIZATION_UPDATED))
      .catch(err => Promise.reject(err))
  }
};
