import {UserAccountService, UserListError} from "@/services/UserAccountService";
import Vue from 'vue';
import {UserEventTypes} from "@/services/PEventBus";
import GroupService from "@/services/GroupService";

const EventBus = new Vue();
export default {
  async fetchAll({commit}) {
    commit('SET_LOADING', true)
    const response = await UserAccountService.fetchAll()
    if (response.status !== 200 || response.data.code) {
      // UserListError cok generic olmus. O yuzden kullandim. Kullanmali miyim?
      throw new UserListError(response.data.code, response.data.message);
    }
    commit('SET_LOADING', false)
    commit('USER_LIST_LOADED', response.data.users)
    EventBus.$emit(UserEventTypes.USERS_LOADED)
  },

  async fetchUsersByGroupId({commit}, groupId) {
    return new Promise((resolve, reject) => {
      GroupService.fetchUsersByGroupId(groupId)
        .then((response) => {
          if (response.data.code) {
            throw new UserListError(response.data.code, response.data.message)
          } else {
            let suggestions = response.data.suggestions
            // todo decide that it is necessary or not
            EventBus.$emit('onUserListLoaded', suggestions)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async invite({commit}, email) {
    const response = await UserAccountService.invite(email);
    if (response.status !== 200 || response.data.code) {
      // UserListError cok generic olmus. O yuzden kullandim. Kullanmali miyim?
      throw new UserListError(response.data.code, response.data.message);
    }
    return response;
  },

  async query({commit}, payload) {
    const response = await UserAccountService.query(payload)
    const users = response.data.suggestions
    commit('USERS_ARE_QUERIED', users)
  },

  async updatePreferredLanguage({commit}, language) {
    const response = await UserAccountService.updatePreferredLanguage(language);

    let userData = JSON.parse(localStorage.getItem("userData"))
    userData.preferredLanguage = language
    localStorage.setItem("userData", JSON.stringify(userData))
    // update local storage
    return response
  }
};
