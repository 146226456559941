import {ApiService} from "./ApiService";

class RuleCreateError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class RuleListLoadError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class UpdateRuleError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class GetRuleError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class ScriptValidationServerError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}


class SaveScriptError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class ExecuteRuleError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}


class AddActionError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class UpdateActionError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class AddReminderError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}


const RuleService = {

  create: async (request, contractId) => {
    return await ApiService.post("/documents/" + contractId + "/rules", request)
  },

  update: async (request, ruleId) => {
    return await ApiService.put("/rules/" + ruleId, request)
  },

  fetchAll: async (contractId) => {
    return await ApiService.get("/documents/" + contractId + "/rules")
  },

  fetchOne: async (ruleId) => {
    return await ApiService.get("/rules/" + ruleId)
  },

  validateScript: async (ruleId, request) => {
    const response = await ApiService.post("/rules/" + ruleId + "/validate-script", request)
    if (response.data.code) {
      throw ScriptValidationServerError(response.data.code.response.data.message)
    }
    return response.data
  },

  saveScript: async (ruleId, request) => {
    return await ApiService.patch("/rules/" + ruleId, request)
  },

  execute: async (ruleId) => {
    return await ApiService.put("/rules/" + ruleId + "/execute")
  },

  addAction: async (ruleId, request) => {
    return await ApiService.post("/rules/" + ruleId + "/actions", request)
  },

  updateAction: async (actionId, request) => {
    return await ApiService.put("/actions/" + actionId, request)
  },

  fetchAllActions: async (ruleId) => {
    return await ApiService.get("/rules/" + ruleId + "/actions")
  },

  addReminder: async (documentId, request) => {
    return await ApiService.post('/documents/' + documentId + "/reminders", request)
  }
}


export default RuleService;

export {
  RuleCreateError,
  RuleListLoadError,
  RuleService,
  GetRuleError,
  UpdateRuleError,
  ScriptValidationServerError,
  SaveScriptError,
  ExecuteRuleError,
  AddActionError,
  UpdateActionError,
  AddReminderError
};
