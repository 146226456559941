import state from './moduleAssignmentState.js'
import mutations from './moduleAssignmentMutations.js'
import actions from './moduleAssignmentActions.js'
import getters from './moduleAssignmentGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
