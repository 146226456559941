import state from './moduleDocumentLifecycleState'
import mutations from './moduleDocumentLifecycleMutations.js'
import actions from './moduleDocumentLifecycleActions.js'
import getters from './moduleDocumentLifecycleGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
