import axios from "axios";
import store from "../store";
import router from "../router";
import useJwt from '@/auth/jwt/useJwt'

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return response;
    }, function (error) {
      if (!error.response) {
        useJwt.clearSession()
        router.push('/login').catch((e) => {
          console.log(e)
        })
      }
      return Promise.reject(error);
    });
  },

  rebindHeader() {
    if (axios.defaults.headers.Authorization === undefined) {
      this.setHeader()
    }
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${useJwt.getToken()}`;
    axios.defaults.headers.common["X-App-Language"] = localStorage.getItem('language') === null ? 'tr' : localStorage.getItem('language')
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource, auth = true) {
    if (auth) {
      this.setHeader()
    }
    return axios.get(resource);
  },

  getFile(resource, auth = true) {
    if (auth) {
      this.setHeader()
    }
    return axios.get(resource, {responseType: 'blob'});
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  upload(resource, data, config) {
    return axios.post(resource, data, config);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },


  delete(resource) {
    return axios.delete(resource, {data: {}});
  },

  deleteWithData(resource, data) {
    return axios.delete(resource, {data: data});
  },

  formData(resource, formData) {
    return axios.post(resource, formData, {
      headers: {"Content-Type": "multipart/form-data"}
    })
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - Auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error.request.status === 401) {
          if (error.config.url.includes("/o/token/")) {
            // Refresh token has failed. Logout the user
            await store.dispatch("auth/logout");
            throw error;
          } else {
            // Refresh the access token
            try {
              await store.dispatch("auth/refreshToken");
              // Retry the original request
              return this.customRequest({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data
              });
            } catch (e) {
              // Refresh has failed - reject the original request
              throw error;
            }
          }
        }

        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  }
};

export {ApiService};
