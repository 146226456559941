import DocumentService, {DocumentsApiOperationError,} from "@/services/DocumentService";
import {
    AddReminderError,
    ExecuteRuleError,
    GetRuleError,
    RuleCreateError,
    RuleListLoadError,
    RuleService,
    UpdateRuleError,
} from "@/services/RuleService";
import LabelService from "../../services/LabelService";
import GroupService from "../../services/GroupService";
import {PEventBus} from "@/services/PEventBus";
import DocumentVariableService from "@/services/DocumentVariableService";
import store from '@/store'
import async from "async";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
// eslint-disable-line no-unused-vars
export default {
    initiate({commit}, request) {
        return DocumentService.initiate(request)
            .then(res => {
                return res.data
            })
            .catch(err => console.error(err));
    },

    createFromTemplate({commit}, payload) {
        return new Promise((resolve, reject) => {
            DocumentService.createFromTemplate(payload)
                .then((response) => {
                    if (response.data.code) {
                        resolve(response.data.code)
                        // omitted temporarily
                        //commit('SET_ERROR', response.data, {root: true})
                        // console.log(response.data)
                    } else {
                        // Created response that hold new id will be returned
                        commit("CREATE_SUCCESS", response.data.uid)
                    }
                    resolve(response.data.uid)
                })
                .catch((error) => {
                    commit('SET_ERROR ', error, {root: true})
                    reject(error)
                })
        })
    },

    createWithContent({commit}, payload) {
        return new Promise((resolve, reject) => {
            DocumentService.createWithContent(payload)
                .then((response) => {
                    if (response.data.code) {
                        // omitted temporarily
                        // commit('SET_ERROR', response.data, {root: true})
                        // console.log(response.data)
                    } else {
                        commit("CREATE_SUCCESS", response.data.uid)
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERROR ', error, {root: true})
                    reject(error)
                })
        })
    },

    async update({commit}, payload) {
        try {
            const response = await DocumentService.update(payload.documentId, payload.title)
            if (response.code) {
                throw Error
            }
            return response.data
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },


    async updateDocumentType({commit}, payload) {
        try {
            const response = await DocumentService.updateDocumentType(payload.documentId, payload.type)
            if (response.code) {
                throw Error
            }
            commit('PUT_ON_DOCUMENT', response)
            return response
        } catch (e) {
            const result = {
                success: false,
                contractId: payload.contractId,
                error: e
            }
        }
    },

    async upload({commit}, payload) {
        const data = new FormData()
        if (payload.file) {
            data.append('file', payload.file)
            data.append('id', payload.id)
            data.append('documentTitle', payload.documentTitle)
            data.append('workspaceId', payload.workspaceId)
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
                console.log(progressEvent)
                const progressData = {
                    id: payload.id,
                    percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
                PEventBus.$emit('onFileUploadProgress', progressData)
            }
        }

        return new Promise((resolve, reject) => {
            DocumentService.upload(data, config)
                .then((response) => {
                    // omit
                    commit('UPDATE_FILE_UPLOAD_SUCCESSFUL', payload.id)
                    resolve(response)
                })
                .catch((error) => {
                    // omit
                    reject(error)
                })
        })
    },

    async convertWord({commit}, payload) {
        const data = new FormData()
        if (payload.file) {
            data.append('file', payload.file)
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
                console.log(progressEvent)
                const progressData = {
                    id: payload.id,
                    percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
                PEventBus.$emit('docx-upload-progress', progressData)
            }
        }

        return new Promise((resolve, reject) => {
            DocumentService.convertWord(data, config)
                .then((response) => {
                    // omit
                    //commit('UPDATE_FILE_UPLOAD_SUCCESSFUL', payload.id)
                    resolve(response)
                })
                .catch((error) => {
                    // omit
                    reject(error)
                })
        })
    },


    async analyze({commit}, payload) {
        let result = {}
        try {
            const response = await DocumentService.analyze(payload)
            result = {
                success: true,
                response: response
            }

        } catch (e) {
            if (e instanceof DocumentsApiOperationError) {
                result = {
                    success: false,
                    error: e
                }

            }
        }
        PEventBus.$emit('onAnalyzeCompleted', result)
    },

    async deleteDocument({commit}, documentId) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentService.deleteDocument(documentId)
                .then((response) => {
                    if (response.data.code) {
                        throw new DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },

    async archiveDocument({commit}, documentId) {
        let result = {}
        try {
            const response = await DocumentService.archiveDocument(documentId)
            result = {
                success: true
            }
            return response
        } catch (e) {
            if (e instanceof DocumentsApiOperationError) {
                result = {
                    success: false,
                    error: e
                }

            }
        }
    },

    fetchDocument({commit}, documentId) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentService.load(documentId)
                .then((response) => {
                    if (response.data.code) {
                        if (response.data.code === 4018) {
                            console.log("no access for document")
                        } else {
                            commit('REFRESH_DOCUMENT_STATE')
                        }
                    } else {
                        commit('PUT_ON_DOCUMENT', response.data)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },

    fetchDocumentHtmlContent({commit}, documentId) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentService.loadHtmlContent(documentId)
                .then((response) => {
                    if (response.data.code) {
                        commit('REFRESH_DOCUMENT_STATE')
                    } else {
                        commit('ADD_CONTENT_HTML', response.data.content)
                    }
                    commit('SET_LOADING', false)
                    resolve(response.data.content)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },

    fetchApprovers({commit}, documentId) {
        return new Promise((resolve, reject) => {
            DocumentService.loadApprovers(documentId)
                .then((response) => {
                    resolve(response.data.sectionApprovals)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    fetchApproverActions({commit}, sectionId) {
        return new Promise((resolve, reject) => {
            DocumentService.loadApproverActions(sectionId)
                .then((response) => {
                    resolve(response.data.actions)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    bulkApproveSections({commit}, documentId) {
        return new Promise((resolve, reject) => {
            DocumentService.bulkApproveSections(documentId)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    loadMyDocuments({commit}, payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            DocumentService.loadMyDocuments()
                .then((response) => {
                    commit("LOAD_MY_DOCUMENTS_SUCCESS", response.data.documents.results)
                    commit("SET_LOADING", false)
                    resolve(response.data.documents)
                })
                .catch(reason => {
                    commit("SET_LOADING", false)
                    reject(reason)
                })
        })
    },

    loadSharedDocuments({commit}, payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            DocumentService.loadSharedDocuments()
                .then((response) => {
                    commit("LOAD_SHARED_DOCUMENTS_SUCCESS", response.data.documents.results)
                    commit("SET_LOADING", false)
                    resolve(response.data.documents)
                })
                .catch(reason => {
                    commit("SET_LOADING", false)
                    reject(reason)
                })
        })
    },

    loadArchivedDocuments({commit}, queryParams) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            DocumentService.loadArchivedDocuments(queryParams)
                .then((response) => {
                    commit("LOAD_ARCHIVED_DOCUMENTS_SUCCESS", response.data.documents)
                    commit("SET_LOADING", false)
                    resolve(response.data.documents)
                })
                .catch(reason => {
                    commit("SET_LOADING", false)
                    reject(reason)
                })
        })
    },

    async applyTemplate({commit}, payload) {
        const response = await DocumentService.applyTemplate(payload.documentId, payload.templateId)
        // todo handle managed exceptions
        return response
    },

    async fetchVariables({commit}, documentId) {
        commit("SET_LOADING_VARIABLES", true)
        try {
            const response = await DocumentVariableService.getIssuedVariables(documentId)
            commit("SET_LOADING_VARIABLES", false)
            if (response.code) {
                // handle error
            }
            commit("SET_DEFINED_VARIABLE_LIST", response.variables)
        } catch (e) {
            commit("SET_LOADING_VARIABLES", false)
            return Promise.reject(e);
        }
    },

    cleanVariables({commit}) {
        commit("SET_DEFINED_VARIABLE_LIST", [])
    },

    refreshDocument({commit}) {
        commit("REFRESH_DOCUMENT_STATE")
    },

    fetchDocumentStatistics({commit}, documentId) {
        return new Promise((resolve, reject) => {
            DocumentService.fetchDocumentStatistics(documentId)
                .then((response) => {
                    if (response.data.code) {
                        //
                    } else {
                        commit('SET_DOCUMENT_STATISTICS', response.data)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async reorderSections({commit}) {
        const sections = store.getters["document/getSections"]
        let reorderedMap = [];
        Object.keys(sections).forEach((key) => {
            let item = {
                idSection: sections[key].sectionId,
                idOrder: key,
            };
            reorderedMap.push(item);
        });
        await store.dispatch("section/reorder", {
            documentId: this.documentId,
            reorderedMap: reorderedMap,
        })
            .then((res) => {

            })
            .catch((e) => {
                commit('SET_ERROR', e, {root: true})
            });
    },

    async getVariable({commit}, {documentId, variableId}) {
        try {
            return await DocumentVariableService.getVariable(documentId, variableId)
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },

    async getVariableUsedInDocument({commit}, {documentId, variableId}) {
        try {
            return await DocumentVariableService.getVariableUsedInDocument(documentId, variableId)
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },


    async addVariable({commit}, {documentId, request}) {
        try {
            const response = await DocumentVariableService.addVariable(documentId, request)
            return Promise.resolve(response)
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },

    async removeVariable({commit}, {documentId, variableId}) {
        try {
            const response = await DocumentVariableService.removeVariable(documentId, variableId)
            return Promise.resolve(response)
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },

    async setVariableValue({commit}, {documentId, variableId, value, overridingDescription}) {
        try {
            const request = {
                userProcessedValue: value,
                approval: true,
                overridingDescription: overridingDescription
            }
            const response = await DocumentVariableService.setVariableValue(documentId, variableId, request)
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },
    async toggleNumbering({commit}, documentId) {
        try {
            const response = await DocumentService.toggleNumbering(documentId)
            commit('SET_DOCUMENT_NUMBERING', documentId)
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },
    async recomposeVariable({commit}, {variableId, userProcessedValue, presentationMethod}) {
        commit('RECOMPOSE_SECTIONS', {variableId, userProcessedValue, presentationMethod})
    },

    createRule({commit}, request) {
        return new Promise((resolve, reject) => {
            RuleService.create(request, request.documentId)
                .then((response) => {
                    if (response.data.code) {
                        throw RuleCreateError(response.data.code, response.data.message)
                    } else {
                        // todo decide that it is necessary or not
                        commit('CREATE_RULE_SUCCESS', response.data)
                        PEventBus.$emit('onRuleCreated', response.data.uid)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateRule({commit}, request) {
        return new Promise((resolve, reject) => {
            RuleService.update(request, request.ruleId)
                .then((response) => {
                    if (response.data.code) {
                        throw UpdateRuleError(response.data.code, response.data.message)
                    } else {
                        PEventBus.$emit('onRuleUpdated', response.data.uid)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchRule({commit}, ruleId) {
        return new Promise((resolve, reject) => {
            RuleService.fetchOne(ruleId)
                .then((response) => {
                    if (response.data.code) {
                        throw GetRuleError(response.data.code, response.data.message)
                    } else {
                        let rule = response.data
                        // todo decide that it is necessary or not
                        PEventBus.$emit('onRuleLoaded', rule)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchRuleList({commit}, request) {
        return new Promise((resolve, reject) => {
            RuleService.fetchAll(request.contractId)
                .then((response) => {
                    if (response.data.code) {
                        throw RuleListLoadError(response.data.code, response.data.message)
                    } else {
                        let ruleList = response.data.list.results
                        // todo decide that it is necessary or not
                        commit('RULE_LIST_LOADED', ruleList)
                        PEventBus.$emit('onContractRuleListingComplete', ruleList)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async validateScript({commit}, request) {
        try {
            let newRequest = Object.assign({}, request)
            delete newRequest['ruleId']
            const response = await RuleService.validateScript(request.ruleId, newRequest)
            PEventBus.$emit('onScriptValidationComplete', response)
        } catch (e) {
            request.notify({
                time: 4000,
                title: "Error",
                text: e.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
            });
        }
    },

    saveScript({commit}, request) {
        return new Promise((resolve, reject) => {
            let newRequest = {script: request.script}
            RuleService.saveScript(request.ruleId, newRequest)
                .then((response) => {
                    if (response.data.code) {
                        throw DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    request.closeLoading()
                    request.saved()
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    executeManually({commit}, payload) {
        return new Promise((resolve, reject) => {
            RuleService.execute(payload.ruleId)
                .then((response) => {
                    if (response.data.code) {
                        throw ExecuteRuleError(response.data.code, response.data.message)
                    }
                    payload.closeLoading()
                    resolve(response)
                })
                .catch((error) => {
                    // todo handle error cases
                    reject(error)
                })
        })
    },

    addAction({commit}, request) {
        return new Promise((resolve, reject) => {
            let ruleId = request.ruleId
            delete request.ruleId
            RuleService.addAction(ruleId, request)
                .then((response) => {
                    if (response.data.code) {
                        throw DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    PEventBus.$emit('onRuleActionCreated', response.data.uid)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateAction({commit}, request) {
        return new Promise((resolve, reject) => {
            let actionId = request.actionId
            delete request.actionId
            RuleService.updateAction(actionId, request)
                .then((response) => {
                    if (response.data.code) {
                        throw DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    PEventBus.$emit("onRuleActionUpdated")
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchActionList({commit}, ruleId) {
        return new Promise((resolve, reject) => {
            RuleService.fetchAllActions(ruleId)
                .then((response) => {
                    if (response.data.code) {
                        throw new RuleListLoadError(response.data.code, response.data.message)
                    } else {
                        let actionList = response.data.actions
                        // todo decide that it is necessary or not
                        PEventBus.$emit('onRuleActionListLoaded', actionList)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    addReminder({commit}, payload) {
        return new Promise((resolve, reject) => {
            let documentId = payload.documentId
            delete payload.documentId
            RuleService.addReminder(documentId, payload)
                .then((response) => {
                    if (response.data.code) {
                        throw AddReminderError(response.data.code, response.data.message)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    addGroup({commit}, payload) {
        return new Promise((resolve, reject) => {
            DocumentService.addGroup(payload)
                .then((response) => {
                    if (response.data.code) {
                        throw DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getDocumentTypes({commit}) {
        const response = await DocumentService.getDocumentTypes()
            .catch(e => {
                commit('SET_ERROR', e, {root: true})
            });
        commit('SET_DOCUMENT_TYPES', response.responses)
    },

    async getDocumentStatuses({commit}) {
        const response = await DocumentService.getDocumentStatuses()
            .catch(e => {
                commit('SET_ERROR', e, {root: true})
            });
        commit('SET_DOCUMENT_STATUSES', response.responses)
    },

    async addToLabels({commit}, label) {
        commit('ADD_LABEL', label)
    },

    async removeFromLabels({commit}, label) {
        commit('REMOVE_LABEL', label)
    },

    async getLabels({commit}) {
        const response = await LabelService.loadAll()
            .catch(e => {
                commit('SET_ERROR', e, {root: true})
            });

        PEventBus.$emit('onLabelsGot', response.data.labels)
    },

    async queryDocuments({commit}, payload) {
        const response = await DocumentService.queryDocuments(payload)
            .catch(e => {
                commit('SET_ERROR', e, {root: true})
            });

        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message)
        }

        commit("LOAD_MY_DOCUMENTS_SUCCESS", response.data.documents.results)
        PEventBus.$emit('onLoadComplete', response.data.documents.results)
    },


    async downloadAsPdfById({commit}, payload) {
        return await DocumentService.downloadAsPdfById(payload.documentId)
            .then(response => {
                const file = new Blob([response.data], {type: response.headers['content-type']});
                //Build a URL from the file
                const data = URL.createObjectURL(file);

                const link = document.createElement("a");
                link.href = data;
                link.download = payload.documentTitle.toLowerCase().replace(" ", "_") + ".pdf";
                // link.click()

                //Open the URL on new Window
                return link
            })
            .catch(err => {
                return err.message
            })
    },

    async downloadAsDocxById({commit}, payload) {
        return await DocumentService.downloadDocxById(payload.documentId)
          .then(response => {
              const blob = new Blob([response.data], {type: response.headers['content-type']});

              if (response.data['type'] === 'application/vnd.openxmlformats-officedocument.wordprocessingm') {
                  //Build a URL from the file
                  const data = URL.createObjectURL(blob);

                  const link = document.createElement("a");
                  link.href = data;
                  link.download = payload.documentTitle.toLowerCase().replace(" ", "_") + ".docx";
                  link.click();
                  //Open the URL on new Window
                  // return link
                  return link
              }
          })
          .catch(err => {
              return err.message
          })
    },

    async downloadArchivedFile({commit}, documentId) {
        try {
            const response = await DocumentService.downloadArchivedFile(documentId);
            const file = new Blob(
                [response.data], {type: response.headers['content-type']});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            return fileURL
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },

    async exportById({commit}, payload) {
        return new Promise((resolve, reject) => {
            DocumentService.exportById(payload.documentId)
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']});

                    if (response.data['type'] === 'application/octet-stream') {
                        //Build a URL from the file
                        const data = URL.createObjectURL(blob);

                        const link = document.createElement("a");
                        link.href = data;
                        link.download = payload.documentTitle.toLowerCase()
                            .replaceAll(" ", "_")
                            .concat("-")
                            .concat(new Date().toLocaleDateString())
                            .concat(".pai");
                        link.click();

                        resolve(link)
                    } else {
                        reject(response.data.exceptionMessage)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async importFromExportedFormat({commit}, payload) {
        const data = new FormData()
        if (payload.file) {
            data.append('file', payload.file)
            data.append('workspaceId', payload.workspaceId)
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            // onUploadProgress: (progressEvent) => {
            //     console.log(progressEvent)
            //     const progressData = {
            //         id: payload.id,
            //         percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
            //     }
            //     PEventBus.$emit('onFileUploadProgress', progressData)
            // }
        }

        return new Promise((resolve, reject) => {
            DocumentService.importFromExportedFormat(data, config)
                .then((response) => {
                    // omit
                    resolve(response)
                })
                .catch((error) => {
                    // omit
                    reject(error)
                })
        })
    },


    async clean({commit}) {
        commit('PUT_ON_DOCUMENT',
            {
                id: null,
                title: 'Adsız doküman',
                code: null,
                createdAt: null,
                status: null,
                templateId: null,
                attributes: null,
                rules: null,
                documentType: null,
                owner: {
                    id: null,
                    fullName: null
                },
                labels: [],
                groups: [],
                htmlContent: null,
            }
        )
        commit('SET_SECTION_LIST', [])
    },

    async simpleQuery({commit}, payload) {
        const response = await DocumentService.simpleQuery(payload);

        commit('SET_QUERIED_DOCS', response.data.documents)
    },

    async addToDocumentLink({commit}, payload) {
        return new Promise((resolve, reject) => {
            DocumentService.addToDocumentLink(payload)
                .then((response) => {
                    if (response.data.code) {
                        throw DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getDocumentRelations({commit}, documentId) {
        const response = await DocumentService.getDocumentRelations(documentId);

        commit('SET_RELATED_DOCS', response.data.documents)
    },

    async getDocumentReminders({commit}, documentId) {
        const response = await DocumentService.getDocumentReminders(documentId);
        commit('SET_REMINDERS', response.data.reminders)
    },

    async getDocumentSignerInfo({commit}, documentId) {
        const response = await DocumentService.getDocumentSignerInfo(documentId);

        commit('SET_DOCUMENT_SIGNERS', response.data.signers)
    },

    async removeDocumentRelation({commit}, payload) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentService.removeDocumentRelation(payload)
                .then((response) => {
                    if (response.data.code) {
                        throw new DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },

    async setDocumentSigner({commit}, payload) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentService.setDocumentSigner(payload)
                .then((response) => {
                    if (response.data.code) {
                        throw DocumentsApiOperationError(response.data.code, response.data.message)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })

    },

    async getDocumentHistory({commit}, documentId) {
        const response = await DocumentService.getDocumentHistory(documentId);

        commit('SET_DOCUMENT_HISTORY', response.data.history)
    },
}
