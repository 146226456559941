import SectionService from "../../services/SectionService";
import DocumentService from "@/services/DocumentService";
import store from '@/store'
import {QuillUtils} from "@/services/Utils";

export default {
  create({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.create(payload.documentId, payload)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returned
            store.commit('document/SECTION_CREATED', response.data.section)
            store.commit('document/SET_DEFINED_VARIABLE_LIST', response.data.variables)
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  edit({commit}, {content, htmlContent, textContent, sectionId, version}) {
    return new Promise((resolve, reject) => {
      const request = {
        sectionId: sectionId,
        content: content,
        htmlContent: htmlContent,
        textContent: textContent,
        version: version
      }
      SectionService.edit(sectionId, request)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Done successfully

            // console.log(response.data.section)
            //store.commit("document/SET_SELECTED_SECTION_SAVED")
            store.commit('document/UPDATE_SECTION', response.data.section)
            store.commit('document/SET_DEFINED_VARIABLE_LIST', response.data.variables)
            store.commit('document/RECOMPOSE_SECTIONS_ALL')
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },
  updateHeader({commit}, {sectionId, header}) {
    return new Promise((resolve, reject) => {
      const request = {
        header: header,
      }
      SectionService.updateHeader(sectionId, request)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Done successfully

            // console.log(response.data.section)
            store.commit('document/UPDATE_SECTION_HEADER', {sectionId: sectionId, header: header})
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },
  async saveUnsavedSections({commit}, documentId) {
    let unsavedSections = store.getters["document/getSectionsUnsaved"]
    let request = []
    unsavedSections.forEach(unsavedSection => {
      request.push({
        sectionId: unsavedSection.sectionId,
        content: unsavedSection.content,
        htmlContent: unsavedSection.htmlContent,
        textContent: unsavedSection.textContent,
        version: unsavedSection.version
      })
    })
    const response = await SectionService.editMany(documentId, request)
    if (response.data.code) {
      // omitted temporarily
      commit('SET_ERROR', response.data, {root: true})
    } else {
      store.commit('document/SET_SECTION_LIST_AS_SAVED', unsavedSections)
    }

  },
  async delete({commit}, sectionId) {
    const response = await SectionService.delete(sectionId);
    // if (response.data.code) {
    //     throw new SectionCrudError(response.data.code, response.data.message)
    // }
    return response.data
  },

  async fetchOne({commit}, sectionId) {
    const response = await SectionService.fetchOne(sectionId);
    store.commit('document/UPDATE_SECTION', response.data.section)
    store.commit('document/SET_DEFINED_VARIABLE_LIST', response.data.variables)
    store.commit('document/RECOMPOSE_SECTIONS_ALL')
    return response.data;
  },

  async reorder({commit}, {documentId, reorderedMap}) {
    const response = await SectionService.reorder(documentId, {reorderedSectionIdList: reorderedMap});
    return response.data;
  },
  fetchAll({commit}, payload) {
    commit('SET_LOADING', true)
    return new Promise((resolve, reject) => {
      SectionService.fetchAll(payload.documentId)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returned
          }
          commit('SET_LOADING', false)
          let sections = response.data.sections
          let variables = response.data.variables
          sections.forEach(section => {
            if (section.accessLevel !== 'NO_ACCESS') {
              section.content = QuillUtils.composeVariables(section.sectionId, section.content, variables)
            }
          })
          store.commit('document/SET_SECTION_LIST', sections)
          store.commit("document/SET_DEFINED_VARIABLE_LIST", response.data.variables)
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          commit('SET_LOADING', false)
          reject(error)
        })
    })
  },

  async addComment({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.addComment(payload.sectionId, payload.request)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be return
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  async resolveComment({commit}, payload) {

  },

  async editComment({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.editComment(payload.sectionId, payload.commentId, payload.request)
        .then((response) => {
          if (response.data.code) {
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be return
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  async deleteComment({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.deleteComment(payload.sectionId, payload.commentId)
        .then((response) => {
          if (response.data.code) {
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be return
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  async fetchAllComments({commit}, {sectionId, documentId}) {
    return new Promise((resolve, reject) => {

      if (sectionId != null) {
        SectionService.fetchAllComments(sectionId)
          .then((response) => {
            commit('ADD_SECTION_COMMENT_LIST', response.data.commentList)
            resolve(response.data.commentList)
          })
          .catch((error) => {
            commit('SET_ERROR', error, {root: true})
            reject(error)
          })
      } else {
        SectionService.fetchAllDocumentVersions(documentId)
          .then((response) => {
            commit('ADD_SECTION_COMMENT_LIST', response.data.commentList)
            resolve(response.data.commentList)
          })
          .catch((error) => {
            commit('SET_ERROR', error, {root: true})
            reject(error)
          })
      }
    })
  },

  fetchSectionVersions({commit}, sectionId) {
    commit('DOCUMENT_VERSIONS_LOADING', true)
    return new Promise((resolve, reject) => {
      if (sectionId != null) {
        SectionService.fetchAllVersionsBySection(sectionId)
          .then((response) => {
            commit('SET_VERSION_LIST', response.data.versionList.results)
            commit('DOCUMENT_VERSIONS_LOADING', false)
            resolve(response.data.versionList.results)
          })
          .catch((error) => {
            commit('SET_ERROR', error, {root: true})
            commit('DOCUMENT_VERSIONS_LOADING', false)
            reject(error)
          })
      }
    })
  },

  fetchAllVersions({commit}, documentId) {
    commit('DOCUMENT_VERSIONS_LOADING', true)
    return new Promise((resolve, reject) => {
      if (documentId != null) {
        SectionService.fetchAllDocumentVersions(documentId)
          .then((response) => {
            commit('SET_VERSION_LIST', response.data.versionList.results)
            commit('DOCUMENT_VERSIONS_LOADING', false)
            resolve(response.data.versionList.results)
          })
          .catch((error) => {
            commit('SET_ERROR', error, {root: true})
            commit('DOCUMENT_VERSIONS_LOADING', false)
            reject(error)
          })
      }
    })
  },

  fetchDiff({commit}, {sectionId, version}) {
    return new Promise((resolve, reject) => {
      SectionService.fetchDiff(sectionId, version)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  addApprover({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.addApprover(payload.sectionId, payload.request)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returnedx§
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  removeApprover({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.removeApprover(payload.sectionId, payload.request.groupId)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returnedx§
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  fetchAllApprovers({commit}, sectionId) {
    return new Promise((resolve, reject) => {
      SectionService.fetchAllApprovers(sectionId)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returnedx§
          }
          commit('SET_SECTION_APPROVERS', response.data.approverList)
          commit('SET_SECTION_ACCESS_LEVEL', response.data.requesterAccessLevel)
          resolve(response.data.approverList)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  async filterSectionsByGroups({commit}, payload) {
    commit('SET_LOADING', true)
    return new Promise((resolve, reject) => {
      DocumentService.filterSectionsByGroups(payload.documentId, payload.request)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returned
          }
          commit('SET_LOADING', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          commit('SET_LOADING', false)
          reject(error)
        })
    })
  },

  async downloadAsPdf({commit}, payload) {

  },

  async updateSectionInStore({commit}, {sectionId, textContent, htmlContent, quillContent}) {
    store.commit('document/UPDATE_SECTION_CONTENT_BY_ID', {sectionId, textContent, htmlContent, quillContent})
    store.commit("document/SET_SELECTED_SECTION_UNSAVED")
  },

  updateApproverRole({commit}, payload) {
    commit('UPDATE_APPROVER_ROLE', payload.request)

    return new Promise((resolve, reject) => {
      SectionService.updateApproverRole(payload)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returnedx§
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  fetchByGroups({commit}, documentId) {
    return new Promise((resolve, reject) => {
      SectionService.fetchSectionsByGroups(documentId)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returnedx§
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  },

  redemandForApprove({commit}, payload) {
    return new Promise((resolve, reject) => {
      SectionService.redemandForApprove(payload)
        .then((response) => {
          if (response.data.code) {
            // omitted temporarily
            commit('SET_ERROR', response.data, {root: true})
          } else {
            // Created response that hold new id will be returnedx§
          }
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          reject(error)
        })
    })
  }
};
