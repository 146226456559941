import Vue from 'vue';
const EventBus = new Vue();
import AssignmentService, {AssignmentServiceCrudError} from "../../services/AssignmentService";

export default {
  fetchInbox({commit}, payload) {
    console.log(payload)
    commit('SET_INBOX_LOADING', true)
    return new Promise((resolve, reject) => {
      AssignmentService.fetchInbox(payload)
        .then((response) => {
          if (response.data.code) {
            throw new AssignmentServiceCrudError(response.data.code, response.data.message)
          } else {
            let inboxList = response.data.assignments
            // todo decide that it is necessary or not
            commit('INBOX_LIST_LOADED', inboxList)
          }
          commit('SET_INBOX_LOADING', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_INBOX_LOADING', false)
          reject(error)
        })
    })
  },
  fetchSent({commit}, payload) {
    commit('SET_SENT_LOADING', true)
    return new Promise((resolve, reject) => {
      AssignmentService.fetchSent(payload)
          .then((response) => {
            if (response.data.code) {
              throw new AssignmentServiceCrudError(response.data.code, response.data.message)
            } else {
              let inboxList = response.data.assignments
              // todo decide that it is necessary or not
              commit('SENT_LIST_LOADED', inboxList)
            }
            commit('SET_SENT_LOADING', false)
            resolve(response.data)
          })
          .catch((error) => {
            commit('SET_SENT_LOADING', false)
            reject(error)
          })
    })
  },
  fetchClosed({commit}, payload) {
    commit('SET_CLOSED_LOADING', true)
    return new Promise((resolve, reject) => {
      AssignmentService.fetchClosed(payload)
          .then((response) => {
            if (response.data.code) {
              throw new AssignmentServiceCrudError(response.data.code, response.data.message)
            } else {
              let inboxList = response.data.assignments
              // todo decide that it is necessary or not
              commit('CLOSED_LIST_LOADED', inboxList)
            }
            commit('SET_CLOSED_LOADING', false)
            resolve(response.data)
          })
          .catch((error) => {
            commit('SET_CLOSED_LOADING', false)
            reject(error)
          })
    })
  }
};
