import TemplateService from "../../services/TemplateService";

import {
    TemplateCrudError
} from "@/services/TemplateService";
import DocumentService from "@/services/DocumentService";

export default {
    fetchAll({commit}) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            TemplateService.fetchAll()
                .then((response) => {
                    commit("TEMPLATES_WERE_FETCHED", response.templates)
                    commit("SET_LOADING", false)
                    resolve(response.templates)
                })
                .catch(reason => {
                    commit("SET_LOADING", false)
                    reject(reason)
                })
        })
    },
    fetchQuickAccessList({commit}) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            TemplateService.fetchQuickAccessList()
                .then((response) => {
                    commit("TEMPLATES_WERE_FETCHED", response.templates)
                    commit("SET_LOADING", false)
                    resolve(response.templates)
                })
                .catch(reason => {
                    commit("SET_LOADING", false)
                    reject(reason)
                })
        })
    },
    async fetchVariables({commit}, templateId) {
        commit("SET_LOADING_VARIABLES", true)
        try {
            const response = await TemplateService.fetchVariables(templateId)
            commit("SET_LOADING_VARIABLES", false)
            if (response.code) {
                // handle error
            }
            commit("TEMPLATE_VARIABLES_WERE_FETCHED", response.variables)
        } catch (e) {
            commit("SET_LOADING_VARIABLES", false)
            return Promise.reject(e);
        }
    },

    async fetchById({commit}, templateId) {
        const response = await TemplateService.fetchById(templateId)
            .catch(error => {});
        commit('TEMPLATE_WAS_FETCHED_BY_ID', response);
    },
    async delete({commit}, templateId) {
        const response = await TemplateService.deleteTemplate(templateId)
            .catch(error => {});
        //commit('TEMPLATE_WAS_DELETED');
    },
    async createFromDocumentMultipart({commit}, payload) {
        // to save thumbnail image we'll use FormData post
        let result = {}
        try {
            const data = new FormData()
            if (payload.thumbnailImage) {
                data.append('thumbnailImage', payload.thumbnailImage)
            }
            data.append('documentId', payload.documentId)
            data.append('organizationId', payload.organizationId)
            data.append('isPublic', payload.isPublic)
            data.append('title', payload.title)

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: (progressEvent) => {
                    const progressData = {
                        id: payload.uid,
                        percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                    console.log(progressData)
                }
            }
            const response = await TemplateService.createFromDocumentMultipart(data, config);


            result.hasError = false
            result.docUid = response.docUid
            result.fileName = response.docFileName

        } catch (e) {
            result.hasError = true
        }
        return result
    },

    async createFromDocument({commit}, payload) {
        return await TemplateService.createFromDocument(payload);
    },

    async update({commit}, {payload, itemId}) {
        const response = await TemplateService.update(itemId, payload);
        if (response.data.done) {
            //commit('ITEM_TITLE_UPDATED', {itemId: itemId, title: payload.title})
        }
    },
};
