export default {
  isSearching: state => {
    return state.isSearching
  },
  companyList: state => {
    return state.companyList
  },

  getCompanyByCode: (state) => (code) => {
    return state.companyList.find(item => item.value === code);
  },

  listCompaniesForSelection:
      (state) => {
        let newList = []
        state.companyList.companies.forEach(element => {
          let el = {
            text: element.name,
            value: element.uuid
          }
          newList.push(el)
        });

        return newList
      }
}
