import {TokenService, UserRepository} from "../../services/StorageService";

export default {
  // eslint-disable-next-line no-unused-vars
  LOGIN_SUCCESS(state, payload) {
    UserRepository.saveAsJson(payload.user);
    TokenService.saveToken(payload.token);
  },
  // eslint-disable-next-line no-unused-vars
  INVITATION_SUCCESS(state, payload) {
    UserRepository.saveAsJson(payload.user);
    TokenService.saveToken(payload.token);
  },
  // eslint-disable-next-line no-unused-vars
  REGISTER_SUCCESS(state, payload) {
    UserRepository.saveAsJson(payload.user);
    TokenService.saveToken(payload.token);
  },
  USERS_ARE_QUERIED(state, users) {
    state.suggestedUsers = users
  },
  USER_LIST_LOADED(state, users) {
    state.users = users
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  }
};
