export default {
  loading: state => {
    return state.loading
  },
  templates: state => {
    return state.templates;
  },
  getTemplateById: state => {
    state.templates.forEach(value => {
      console.log(value)
    })
  }
}
