import state from './moduleGroupState.js'
import mutations from './moduleGroupMutations.js'
import actions from './moduleGroupActions.js'
import getters from './moduleGroupGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
