import actions from './moduleReminderActions.js'
import getters from './moduleReminderGetters.js'
import mutations from './moduleReminderMutations.js'
import state from './moduleReminderState.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
