import {ApiService} from "./ApiService";

class OrganizationNotFoundError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class OrganizationsCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class OrganizationUpdateError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class UserListLoadError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const WORKSPACES_API_PATH = '/workspaces'

const WorkspaceService = {
  fetchMyOrganizations: async () => {
    return ApiService.get(`${WORKSPACES_API_PATH}`)
  },

  fetchById: async (organizationId) => {
    const response = await ApiService.get(`${WORKSPACES_API_PATH}/${organizationId}`)
    if (response.status === 500) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  fetchMembers: async (organizationId) => {
    const response = await ApiService.get(`${WORKSPACES_API_PATH}/${organizationId}/users`)
    if (response.status === 500) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  fetchGroups: async (workspaceId) => {
    const response = await ApiService.get(`${WORKSPACES_API_PATH}/${workspaceId}/groups`)
    if (response.status === 500) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  fetchInvitations: async (organizationId) => {
    const response = await ApiService.get(`${WORKSPACES_API_PATH}/${organizationId}/invitations`)
    if (response.status === 500) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  create: async (request) => {
    // DO NOT REMOVE. ON REGISTRATION COMPLETE STEP AUTHORIZATION HEADER DOES BIND AND WE SHOULD BIND IT FOR REQUEST
    ApiService.rebindHeader()

    const response = await ApiService.post(`${WORKSPACES_API_PATH}`, request)
    if (response.data.code) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response.data
  },

  update: async (organizationId, request) => {
    const response = await ApiService.put(`${WORKSPACES_API_PATH}/${organizationId}`, request)
    if (response.data.code) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  delete: async (organizationId) => {
    const response = await ApiService.delete(`${WORKSPACES_API_PATH}/${organizationId}`)
    if (response.data.code) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  addUser: async (organizationId, request) => {
    const response = await ApiService.post(`${WORKSPACES_API_PATH}/${organizationId}/users`, request)
    if (response.data.code) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  removeUser: async (organizationId, userId) => {
    const response = await ApiService.delete(`${WORKSPACES_API_PATH}/${organizationId}/users/${userId}`)
    if (response.data.code) {
      throw new OrganizationsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  invite: async (organizationId, request) => {
    try {
      const response = await ApiService.put(`${WORKSPACES_API_PATH}/${organizationId}/users`, request)

      if (response.data.code === 5003) {
        return new OrganizationsCrudError(5003, "")
      } else if (response.data.code === 1002) {
        return new OrganizationsCrudError(1002, "")
      } else if (response.data.code === 2000) {
        return new OrganizationsCrudError(2000, "")
      } else if (response.data.code === 2002) {
        return new OrganizationsCrudError(2002, "")
      } else if (response.data.code) {
        return new OrganizationsCrudError(500, "")
      }

      return response
    } catch (e) {
      if (e.response.data.exceptionMessage === 'email: must be a well-formed email address') {
        return new OrganizationsCrudError(400, "")
      } else {
        throw new OrganizationsCrudError(500, "")
      }
    }

    // return ApiService.put(`${WORKSPACES_API_PATH}/${organizationId}/users`, request)
    //   .then(response => {
    //     if (response.data.code === 5003) {
    //       debugger
    //       throw new  OrganizationsCrudError(5003, "")
    //     } else if (response.data.exceptionMessage === 'email: must be a well-formed email address') {
    //       debugger
    //       throw new OrganizationsCrudError(400, "")
    //     } else if (response.data.code) {
    //       debugger
    //       throw new OrganizationsCrudError(500, "")
    //     }
    //
    //     return response
    //   })
    //   .catch(() => {
    //     debugger
    //     throw new OrganizationsCrudError(500, "")
    //   })
  },

  setDefaultOrganization: async (payload) => {
    const response = await ApiService.post(`${WORKSPACES_API_PATH}/set-default`, payload)
    if (response.data.code) {
      throw new OrganizationUpdateError(response.data.code, response.data.message)
    }
    return response
  }
}

export default WorkspaceService

export {OrganizationNotFoundError, UserListLoadError, OrganizationsCrudError}
