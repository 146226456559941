import LibraryDataPoolService from "../../services/LibraryDataPoolService";
import DocumentService from "../../services/DocumentService";
import VariableDefinitionService from "../../services/VariableDefinitionService";
import {PEventBus} from "@/services/PEventBus";

export default {
  fetchAll({commit}) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      LibraryDataPoolService.fetchAll()
        .then((response) => {
          commit("TEMPLATES_WERE_FETCHED", response.templates)
          PEventBus.$emit('onTemplatesLoaded', response.templates)
          commit("SET_LOADING", false)
          resolve(response.templates)
        })
        .catch(reason => {
          commit("SET_LOADING", false)
          reject(reason)
        })
    })
  },
  async fetchById({commit}, templateId) {
    const response = await LibraryDataPoolService.fetchById(templateId)
      .catch(error => {});

    commit('TEMPLATE_WAS_FETCHED_BY_ID', response);
    PEventBus.$emit('onTemplateByIdLoaded', response);
  },
  async delete({commit}, templateId) {
    const response = await LibraryDataPoolService.deleteItem(templateId)
      .catch(error => {});

    commit('TEMPLATE_WAS_DELETED');
    PEventBus.$emit('onTemplateDeleted');
  },
  async getDocumentTypes({commit}) {
    const response = await DocumentService.getDocumentTypes()
      .catch(error => {});
    commit('DOCUMENT_TYPES_LOADED', response.responses)
  },
  async save({commit}, payload) {
    const response = await LibraryDataPoolService.saveTemplate(payload);
    PEventBus.$emit('onTemplateSaved', response);
  },
  async updateTitle({commit}, {payload, itemId}) {
    const response = await LibraryDataPoolService.updateLibraryItemTitle(payload, itemId);
    if(response.data.done) {
      commit('ITEM_TITLE_UPDATED', {itemId: itemId, title: payload.title})
    }
  },
  async toggleRequired({commit}, payload) {
    await LibraryDataPoolService.toggleRequired(payload.templateId, payload.attributeId, payload.request)
  }
};
