import router from '../../router'
import store from "@/store";
import {QuillUtils} from "@/services/Utils";

export default {
    CREATE_SUCCESS(state, uid) {
        // save into cache
        router.push("/contracts/edit/" + uid)
    },
    SET_LOADING(state, loading) {
        state.listLoading = loading
    },
    SET_LOADING_VARIABLES(state, loading) {
        state.variablesLoading = loading
    },
    SET_SECTION_LIST(state, list) {
        state.sections = list
    },
    SET_DEFINED_VARIABLE_LIST(state, list) {
        state.variableList = list.sort((item1, item2) => {
            if (item1.name < item2.name) {
                return 1
            }
            return -1
        })
    },
    ADD_TO_VARIABLE_BLOT_LIST(state, variableBlotInstance) {
        state.variableBlotList.push(variableBlotInstance)
    },
    SET_SECTION_LIST_AS_SAVED(state, unsavedList) {
        state.unsavedSectionIds = []
    },
    SET_QUILL_INSTANCE(state, {sectionId, quillInstance}) {
        let sec = state.sections.find(value => value.sectionId === sectionId)
        sec.quillInstance = quillInstance
        let index = state.sections.indexOf(sec)
        state.sections.splice(index, 1, sec)
    },
    UPDATE_SECTION(state, section) {
        let sec = state.sections.find(value => value.sectionId === section.sectionId)
        let index = state.sections.indexOf(sec)
        state.sections.splice(index, 1, section)
    },
    UPDATE_SECTION_HEADER(state, {sectionId, header}) {
        let sec = state.sections.find(value => value.sectionId === sectionId)
        sec.header = header
    },
    UPDATE_SECTION_CONTENT_BY_ID(state, {sectionId, textContent, htmlContent, quillContent}) {
        let section = state.sections.find(value => value.sectionId === sectionId)

        section.content = quillContent
        section.htmlContent = htmlContent
        section.textContent = textContent

        let index = state.sections.indexOf(section)
        state.sections.splice(index, 1, section)
    },
    SECTION_CREATED(state, section) {
        state.sections.push(section)
    },
    RECOMPOSE_SECTIONS(state, updatedVariableValueObject) {
        state.sections.forEach(section => {
            section.content = QuillUtils.composeVariables(section.sectionId, section.content, [updatedVariableValueObject])
        })
    },
    RECOMPOSE_SECTIONS_ALL(state) {
        state.sections.forEach(section => {
            section.content = QuillUtils.composeVariables(section.sectionId, section.content, state.variableList)
        })
    },
    UPDATE_LIST(state, list) {
        state.sections = list
    },
    LOAD_MY_DOCUMENTS_SUCCESS(state, list) {
        state.myDocumentList = list
    },
    LOAD_SHARED_DOCUMENTS_SUCCESS(state, list) {
        state.sharedDocumentList = list
    },
    LOAD_ARCHIVED_DOCUMENTS_SUCCESS(state, list) {
        state.archivedDocumentList = list
    },
    UNSHIFT_FILE_ITEM_IN_ARCHIVED_LIST(state, fileItem) {
        state.toBeUploadedFileList.unshift(fileItem)
    },
    REMOVE_FILE_ITEM_IN_ARCHIVED_LIST(state, file) {
        const index = state.toBeUploadedFileList.findIndex(value => value.uid === file.uid)
        state.toBeUploadedFileList.splice(index, 1)
    },
    UPDATE_FILE_UPLOAD_SUCCESSFUL(state, id) {
        let fileItem = state.toBeUploadedFileList.find(value => value.uid === id)
        fileItem.status = 'uploaded'
        const index = state.toBeUploadedFileList.indexOf(fileItem)
        state.toBeUploadedFileList.splice(index, 1, fileItem)
    },
    REMOVE_SUCCESSFUL_UPLOADED_FILES(state) {
        for (let i = state.toBeUploadedFileList.length - 1; i >= 0; i--) {
            if (state.toBeUploadedFileList[i].status === 'uploaded') {
                state.toBeUploadedFileList.splice(i, 1)
            }
        }
        console.log(state.toBeUploadedFileList)

    },
    UPDATE_FILE_UPLOAD_PROGRESS(state, uploadProgress) {
        console.log(uploadProgress)
        let fileItem = state.toBeUploadedFileList.find(value => value.uid === uploadProgress.id)
        fileItem.percentage = uploadProgress.percentage
        const index = state.toBeUploadedFileList.indexOf(fileItem)
        state.toBeUploadedFileList.splice(index, 1, fileItem)
    },
    REFRESH_DOCUMENT_STATE(state) {
        state.document = state.emptyDocument
    },
    SET_DOCUMENT_STATISTICS(state, data) {
        state.statistics = data
    },
    PUT_ON_DOCUMENT(state, data) {
        state.document = data
    },
    ADD_CONTENT_HTML(state, data) {
        state.document.htmlContent = data
    },
    ADD_LABEL(state, label) {
        state.document.labels.push(label)
    },
    REMOVE_LABEL(state, label) {
        state.document.labels = state.document.labels.filter(l => l !== label)
    },

    CREATE_RULE_SUCCESS(state) {
    },

    RULE_LIST_LOADED(state, list) {
        state.ruleList = list
    },
    SET_DOCUMENT_TYPES(state, documentTypes) {
        state.documentTypes = documentTypes
    },
    SET_DOCUMENT_STATUSES(state, documentStatuses) {
        state.documentStatuses = documentStatuses
    },
    SET_SELECTED_SECTION_FORMAT(state, formats) {
        state.selectedSectionStatus.formats = formats
    },
    SET_SELECTED_SECTION_ID(state, sectionId) {
        state.selectedSectionStatus.sectionId = sectionId
    },
    SET_SELECTED_SECTION_UNSAVED(state) {
        state.selectedSectionStatus.unsaved = true
    },
    SET_SELECTED_SECTION_SAVED(state) {
        state.selectedSectionStatus.unsaved = false
        state.selectedSectionStatus.autosaving = false
    },
    SET_SELECTED_SECTION_AUTO_SAVING(state) {
        state.selectedSectionStatus.autosaving = true
    },
    SET_SECTION_UNSELECTED(state) {
        state.selectedSectionStatus.sectionId = null
        state.selectedSectionStatus.formats = null
        state.selectedSectionStatus.unsaved = false
        state.selectedSectionStatus.autosaving = false
    },
    SET_APPROVER_SELECTION(state, payload) {
        state.approverSelection = payload.toggle
        state.selectedSectionId = payload.sectionId
    },
    SET_QUERIED_DOCS(state, documents) {
        state.queryDocResult = documents
    },
    SET_RELATED_DOCS(state, documents) {
        state.relatedDocuments = documents
    },
    SET_DOCUMENT_HISTORY(state, history) {
        state.history = history
    },
    SET_REMINDERS(state, reminders) {
        state.reminders = reminders
    },
    SET_DOCUMENT_SIGNERS(state, signers) {
        signers.forEach(s => s.selected = false)

        state.possibleDocumentSigners = signers
    },
    SAVE_LAST_INDEX_ON_EDITOR(state, payload) {
        state.sections.forEach(section => {
            if (section.sectionId === payload.sectionId) {
                section.lastFocusedIndex = payload.lastFocusedIndex
            }
        })
    },
    SET_EDITOR_FOCUS_BY_SECTION_ID(state, sectionId) {
        console.log(sectionId)
        state.focusedSectionId = null
        state.focusedSectionId = sectionId
    },
    SET_DOCUMENT_NUMBERING(state) {
        state.document.numbering = !state.document.numbering
    },
    HANDLE_VARIABLE_CLICKED(state, payload) {
        console.log('HANDLE_VARIABLE_CLICKED')
        state.variableClickData = {
            clicked: true,
            data: payload,
        }
    },
    HANDLE_VARIABLE_CLICKED_CLOSE_MODAL(state) {
        console.log('HANDLE_VARIABLE_CLICKED_CLOSE_MODAL')
        state.variableClickData = {
            clicked: false,
        }
    }
};
