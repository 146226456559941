

export default {
  ENABLE_LOADING(state) {
    state.listLoading = true
  },
  DISABLE_LOADING(state) {
    state.listLoading = false
  },
  ATTRIBUTES_LOAD_SUCCESS(state, response) {
    state.variableList = response.variables
  },
  VARIABLE_TYPES(state, response) {
    state.variableTypes = response.pairs
  },
  VARIABLE_VALUE_TYPES(state, response) {
    state.variableValueTypes = response.pairs
  }
};
