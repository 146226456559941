const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

const USER_INFO = 'user_info'

const CURRENT_CONTRACT_ID = 'contract_uid'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }

}

const UserRepository = {
    saveAsJson(user) {
        localStorage.setItem(USER_INFO, JSON.stringify(user));
    },

    getCurrentUser() {
        if (localStorage.getItem(USER_INFO)) {
            return JSON.parse(localStorage.getItem(USER_INFO))
        }
        return null
    }
}

const ContractRepository = {
    saveContractId(uid) {
        localStorage.setItem(CURRENT_CONTRACT_ID, uid)
    },

    getCurrentContractId() {
        return localStorage.getItem(CURRENT_CONTRACT_ID)
    },

    deleteCurrentContractId() {
        localStorage.removeItem(CURRENT_CONTRACT_ID)
    }
}

export { TokenService, UserRepository, ContractRepository }