
// Modules

import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import state from "./state"
import mutations  from "@/store/mutations";
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleAuth from "./Auth/moduleAuth.js"
import moduleUser from "./Users/moduleUser.js"
import moduleDocument from "./Documents/moduleDocument.js"
import moduleDocumentLifecycle from "./DocumentLifecycle/moduleDocumentLifecycle.js"
import moduleCompany from "./Companies/moduleCompany.js"
import moduleAssignment from './Assignments/moduleAssignment.js'
import moduleDashboard from './Dashboard/moduleDashboard.js'
import moduleLibrary from './LibraryData/moduleLibrary.js';
import moduleAttachment from './Attachment/moduleAttachment.js';
import moduleTemplate from './Template/moduleTemplate.js';
import moduleReminder from "./Reminders/moduleReminder.js";
import moduleLabel from "./Labels/moduleLabel.js";
import moduleGroup from "./Groups/moduleGroup";
import moduleSection from "./Sections/moduleSection";
import moduleComment from "./Comments/moduleComment";
import moduleReview from "./Reviews/moduleReview";
import moduleOrganization from "./Organizations/moduleOrganization";
import moduleVariables from "./Variables/moduleAttribute";

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: moduleAuth,
    document: moduleDocument,
    documentLifecycle: moduleDocumentLifecycle,
    company: moduleCompany,
    user: moduleUser,
    variables: moduleVariables,
    assignment: moduleAssignment,
    dashboard: moduleDashboard,
    library: moduleLibrary,
    attachment: moduleAttachment,
    template: moduleTemplate,
    reminder: moduleReminder,
    label: moduleLabel,
    group: moduleGroup,
    comment: moduleComment,
    section: moduleSection,
    reviews: moduleReview,
    workspaces: moduleOrganization
  },
  strict: process.env.NODE_ENV !== 'production',
})
