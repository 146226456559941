export default {
  COMPANY_SEARCH_STARTED(state, payload) {
    state.isSearching = true
    state.companyList = []
  },

  COMPANY_SEARCH_SUCCESS(state, payload) {
    let newlist = []
    payload.forEach(element => {
      let el = {
        text: element.name,
        value: element.companyCode
      }
      newlist.push(el)
    });
    state.companyList = newlist
    state.isSearching = false
  },

  COMPANY_SEARCH_ENDED(state, payload) {
    state.isSearching = false
  },

  COMPANY_LIST_SUCCESS(state, payload) {
    state.companyList = payload
  },

  ADD_COMPANY(state, item) {
    state.companyList.splice(0, 0, item)
  },
  UPDATE_COMPANY(state, item) {
    state.companyList.splice(0, 0, item)
  }
};
