export default [
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/views/Templates/List/TemplateList'),
  },
  // {
  //   path: '/templates/:id',
  //   name: 'editor',
  //   component: () => import('@/views/Documents/Editor/EditorScreen'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'editor-dox',
  //   },
  // },
]