export default [
  {
    path: '/workspaces',
    name: 'organizations',
    component: () => import('@/views/Workspace/List/WorkspaceList'),
  },
  {
    path: '/workspaces/:id',
    name: 'workspace-detail',
    component: () => import('@/views/Workspace/Workspace'),
  },
  {
    path: '/first-workspace',
    name: 'first-workspace',
    component: () => import('@/views/Workspace/Create/FirstWorkspace'),
    meta: {
      layout: 'full',
    },
  }
]