import state from './moduleTemplateState.js'
import mutations from './moduleTemplateMutations.js'
import actions from './moduleTemplateActions.js'
import getters from './moduleTemplateGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
