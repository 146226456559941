import SectionService from "@/services/SectionService";

export default {
  async fetchAll({commit}) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SectionService.fetchWaitingForReviews()
        .then((response) => {
          if (response.data.code) {
            commit('SET_ERROR', response.data, {root: true})
          } else {
            commit('REVIEWS_FETCHED', response.data.sections)
          }
          commit("SET_LOADING", false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          commit("SET_LOADING", false)
          reject(error)
        })
    });
  },

  async approve({commit}, payload) {
    await SectionService.approve(payload.documentId, payload.sectionId)
  },

  async reject({commit}, payload) {
    return SectionService.reject(payload)
  }
};
