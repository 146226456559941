export default {
  SET_INBOX_LOADING(state, loading) {
    state.inboxLoading = loading
  },
  SET_SENT_LOADING(state, loading) {
    state.sentLoading = loading
  },
  SET_CLOSED_LOADING(state, loading) {
    state.closedLoading = loading
  },
  INBOX_LIST_LOADED(state, inbox) {
    state.inboxList = inbox
  },
  SENT_LIST_LOADED(state, sentList) {
    state.sentList = sentList
  },
  CLOSED_LIST_LOADED(state, closedList) {
    state.closedList = closedList
  }
};
