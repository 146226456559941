import {ApiService} from "./ApiService";

const Types = {
  ORG: 'ORG',
  ORG_BANK: 'ORG_BANK',
  DATE: 'DATE',
  EXP: 'EXPRESSION',
  LOC: 'LOC',
  IBAN: 'IBAN',
  ART: 'ART',
  NO: 'NO',
  MONEY: 'MONEY',
  SUB: 'SUB',
  DURATION: 'DURATION',
  DOC_TYPE: 'DOC_TYPE',
  ALPHANUMERIC: 'ALPHANUMERIC'
};

const InputCardType = {
  COMPANY_CARD: "COMPANY_CARD",
  FREE_TEXT_CARD: "FREE_TEXT_CARD",
  TEXT_AREA_CARD: "TEXT_AREA_CARD",
  DATE_CARD: "DATE_CARD",
  SELECTION: "SELECTION"
};

class AttributeLoadError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class AttributeUpdateError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class AttributeDeleteError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const VariableDefinitionService = {
  loadAll: async () => {
    const response = await ApiService.patch("/variables", {})
    if (response.data.code) {
      throw new AttributeLoadError(response.data.code, response.data.message);
    }
    return response.data
  },

  update: async (variableId, payload) => {
    const response = await ApiService.put(`/variables/${variableId}`, payload)
    if (response.data.code) {
      throw new AttributeUpdateError(response.data.code, response.data.message);
    }
    return response.data
  },

  deleteVariable: async (variableId) => {
    const response = await ApiService.delete(`/variables/${variableId}`)
    if (response.data.code) {
      throw new AttributeDeleteError(response.data.code, response.data.message);
    }
    return response.data
  },

  getVariable: async (variableId) => {
    const response = await ApiService.get(`/variables/${variableId}`)
    if (response.data.code) {
      throw new AttributeDeleteError(response.data.code, response.data.message);
    }
    return response.data
  },

  getVariableTypes: async () => {
    const response = await ApiService.get("/variable-types")
    if (response.data.code) {
      throw new AttributeLoadError(response.data.code, response.data.message);
    }
    return response.data
  },

  getVariableValueTypes: async () => {
    const response = await ApiService.get("/variable-value-types")
    if (response.data.code) {
      throw new AttributeLoadError(response.data.code, response.data.message);
    }
    return response.data
  },

  addVariable: async (payload) => {
    const response = await ApiService.post("/variables", payload)
    if (response.data.code) {
      throw new AttributeLoadError(response.data.code, response.data.message);
    }
    return response.data
  },

  addLinkedVariable: async (payload) => {
    const response = await ApiService.post("/variables/linked", payload)
    if (response.data.code) {
      throw new AttributeLoadError(response.data.code, response.data.message);
    }
    return response.data
  },

  getAttributeInputType(type) {
    switch (type) {
      case Types.ORG:
        return InputCardType.COMPANY_CARD
      case Types.ORG_BANK:
        return InputCardType.SELECTION
      case Types.DATE:
        return InputCardType.DATE_CARD
      case Types.EXP:
      case Types.ART:
      case Types.IBAN:
      case Types.LOC:
      case Types.NO:
      case Types.DURATION:
      case Types.ALPHANUMERIC:
      case Types.DOC_TYPE:
      case Types.MONEY:
        return InputCardType.FREE_TEXT_CARD
      case Types.SUB:
        return InputCardType.TEXT_AREA_CARD
      default:
        break;
    }
  }
}

export default VariableDefinitionService;

export {VariableDefinitionService, AttributeLoadError, InputCardType};
