export default [
  {
    path: '/documents/my',
    name: 'my-documents',
    component: () => import('@/views/Documents/List/ListScreen'),
  },
  {
    path: '/documents/shared',
    name: 'shared-documents',
    component: () => import('@/views/Documents/Shared/SharedDocumentListScreen'),
  },
  {
    path: '/documents/:id/editor',
    name: 'editor',
    component: () => import('@/views/Documents/Editor/MainView'),
    meta: {
      layout: 'full',
      resource: 'Editor',
      contentClass: 'editor-dox',
    },
  },
  {
    path: '/documents/archive',
    name: 'archived-documents',
    component: () => import('@/views/Documents/Archive/List/ArchivedDocumentListScreen'),
  }
]