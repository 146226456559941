export default {
    getSectionIdInComments: state => id => {
        Object.keys(state.documentComments).forEach(key => {
            if(state.documentComments[key].commentId === id) {
                return state.documentComments[key].sectionId
            }
        })
        return null
    },
}
