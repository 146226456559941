import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const baseURL = process.env.VUE_APP_API_URL

const {jwt} = useJwt(axios,
    {  // Endpoints
      loginEndpoint: baseURL + '/login',
      registerEndpoint: baseURL + '/register',
      invitationCompleteEndpoint: baseURL + '/invite/complete',
      registrationCompleteEndpoint: baseURL + '/register/complete',
      fetchUserDataEndpoint: baseURL + '/user-data',
      refreshEndpoint: '/jwt/refresh-token',
      logoutEndpoint: '/jwt/logout',

      // This will be prefixed in authorization header with token
      // e.g. Authorization: Bearer <token>
      tokenType: 'Bearer',

      // Value of this property will be used as key to store JWT token in storage
      storageTokenKeyName: 'accessToken',
      storageRefreshTokenKeyName: 'refreshToken',
    })
export default jwt
