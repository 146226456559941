export default {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  ATTRIBUTES(state, payload) {
    state.attributes = payload
  },
  TEMPLATES_WERE_FETCHED(state, payload) {
    state.itemList = payload;
  },
  DOCUMENT_TYPES_LOADED(state, types) {
    state.documentTypes = types
  },
  ITEM_TITLE_UPDATED(state, response) {
    const updatedItemIndex = state.itemList.findIndex(item => item.id === response.itemId)
    state.itemList[updatedItemIndex].title = response.title
  }
};
