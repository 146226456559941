import {ApiService} from "./ApiService";
import {TokenService} from "@/services/StorageService";

class CompanyNotFound extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

class CompanyListError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

class CompanyDeleteError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const CompanyService = {
    search: async (text) => {
        const response = await ApiService.get("/companies?search=" + text)
        if (response.data.code) {
            throw new CompanyListError(response.data.code, response.data.message);
        }

        return response.data
    },
    list: (workspaceId) => {
        ApiService.setHeader()
        return ApiService.get(`/workspaces/${workspaceId}/companies`)
    },
    create: (request, workspaceId) => {
        delete request.workspaceId
        return ApiService.post(`/workspaces/${workspaceId}/companies`, request)
    },
    update: async (request, companyId, workspaceId) => {
        delete request.workspaceId
        delete request.companyId
        const response = await ApiService.put(`/companies/${companyId}`, request)
        if (response.data.code) {
            throw new CompanyDeleteError(response.data.code, response.data.message);
        }
        return response
    },
    delete: async (companyId, workspaceId) => {
        const response = await ApiService.delete(`/workspaces/${workspaceId}/companies` + companyId);
        if (response.data.code) {
            throw new CompanyDeleteError(response.data.code, response.data.message);
        }
        return response.data
    }
}

export default CompanyService;

export {CompanyService, CompanyNotFound, CompanyListError, CompanyDeleteError};
