export default {
  organizations: state => {
    return state.organizations
  },
  loading: state => {
    return state.loading
  },
  groups: state => {
    return state.groups
  }
}
