import ReminderService from "../../services/ReminderService";
import {PEventBus, ReminderEventTypes} from "@/services/PEventBus";

export default {
  async fetchAll({commit}, documentId) {
    const response = await ReminderService.fetchAll(documentId)
    const reminders = response.data.reminders
    commit('REMINDERS_FETCHED', reminders)
    PEventBus.$emit(ReminderEventTypes.REMINDERS_LOADED)
  },
  async create({commit}, payload) {
    await ReminderService.create(payload.request, payload.documentId)
    PEventBus.$emit(ReminderEventTypes.REMINDER_CREATED)
  },
  async delete({commit}, reminderId) {
    await ReminderService.delete(reminderId)
    PEventBus.$emit(ReminderEventTypes.REMINDER_DELETED)
  },
  async update({commit}, payload) {
    let reminderId = payload.reminderId
    delete payload.reminderId
    await ReminderService.update(payload, reminderId)
    PEventBus.$emit(ReminderEventTypes.REMINDER_UPDATED)
  },
  async start({commit}, reminderId) {
    PEventBus.$emit(ReminderEventTypes.REMINDER_UPDATED)
    return await ReminderService.start(reminderId)
  },
  async pause({commit}, reminderId) {
    await ReminderService.pause(reminderId)
    PEventBus.$emit(ReminderEventTypes.REMINDER_UPDATED)
  },
  async sendTestMail({commit}, request) {
    await ReminderService.sendTestMail(request)
    PEventBus.$emit(ReminderEventTypes.REMINDER_TEST_SENT)
  },
}
