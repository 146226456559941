import {VariableDefinitionService} from "../../services/VariableDefinitionService";
import {PEventBus, VariableEventTypes} from "@/services/PEventBus";

export default {
    loadAll({commit}) {
        commit('ENABLE_LOADING')
        VariableDefinitionService.loadAll()
            .then(response => {
                commit('ATTRIBUTES_LOAD_SUCCESS', response)
                commit('DISABLE_LOADING')
            })
            .catch(error => {
                commit('DISABLE_LOADING')
            })
    },

    getTypes({commit}) {
        VariableDefinitionService.getVariableTypes()
            .then(response => {
                commit('VARIABLE_TYPES', response)
            })
            .catch(error => {
            })
    },
    getValueTypes({commit}) {
        VariableDefinitionService.getVariableValueTypes()
            .then(response => {
                commit('VARIABLE_VALUE_TYPES', response)
            })
            .catch(error => {
            })
    },
    addVariable({commit}, payload) {
        VariableDefinitionService.addVariable(payload)
            .then((response) => {
                PEventBus.$emit(VariableEventTypes.VARIABLE_CREATED)
            })
            .catch(error => {
                PEventBus.$emit(VariableEventTypes.VARIABLE_CREATION_FAILED)
            })
    },
    addLinkedVariable({commit}, payload) {
        VariableDefinitionService.addLinkedVariable(payload)
            .then((response) => {
                PEventBus.$emit(VariableEventTypes.VARIABLE_CREATED)
            })
            .catch(error => {
                PEventBus.$emit(VariableEventTypes.VARIABLE_CREATION_FAILED)
            })
    },

    updateOne({commit}, payload) {
        return new Promise((resolve, reject) => {
            VariableDefinitionService.update(payload.variableId, payload.request)
                .then(response => {
                    resolve(response.data)
                    PEventBus.$emit(VariableEventTypes.VARIABLE_UPDATED)
                })
                .catch(error => {
                    PEventBus.$emit(VariableEventTypes.VARIABLE_UPDATE_FAILED)
                    reject(error)
                })
        })
    },

  deleteOne({commit}, variableId) {
    return new Promise((resolve, reject) => {
      VariableDefinitionService.deleteVariable(variableId)
        .then(response => {
          resolve(response.data)
          PEventBus.$emit(VariableEventTypes.VARIABLE_DELETED)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getOne({commit}, variableId) {
    return new Promise((resolve, reject) => {
      VariableDefinitionService.getVariable(variableId)
        .then(response => {
          resolve(response)
          PEventBus.$emit(VariableEventTypes.VARIABLE_DELETED)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
};
