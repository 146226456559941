import CommentService from "../../services/CommentService";
import store from '@/store'

export default {
    async postComment({commit}, payload) {
        return new Promise((resolve, reject) => {
            CommentService.postComment(payload)
                .then((response) => {
                    if (response.data.code) {
                        // omitted temporarily
                        commit('SET_ERROR', response.data, {root: true})
                    } else {
                        // omitted
                        if (payload.sectionId === null) {
                            commit('SET_ONE_DOCUMENT_COMMENT', response.data)
                        } else {
                            commit('SET_ONE_SECTION_COMMENT', response.data)
                        }
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    // async resolveComment({commit}, payload) {
    // },

    async editComment({commit}, payload) {
        return new Promise((resolve, reject) => {
            CommentService.editComment(payload.sectionId, payload.commentId, payload.request)
                .then((response) => {
                    if (response.data.code) {
                        commit('SET_ERROR', response.data, {root: true})
                    } else {
                        // Created response that hold new id will be return
                        commit('SET_LOADING', false)
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    async resolveComment({commit}, payload) {
        return new Promise((resolve, reject) => {
            CommentService.resolveComment(payload.sectionId, payload.commentId)
                .then((response) => {
                    if (response.data.code) {
                        commit('SET_ERROR', response.data, {root: true})
                    } else {
                        // Created response that hold new id will be return
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    async deleteComment({commit}, payload) {
        return new Promise((resolve, reject) => {
            CommentService.deleteComment(payload.sectionId, payload.commentId)
                .then((response) => {
                    if (response.data.code) {
                        commit('SET_ERROR', response.data, {root: true})
                    } else {
                        // Created response that hold new id will be return
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },

    async fetchSectionComments({commit}, sectionId) {
        return new Promise((resolve, reject) => {
            CommentService.fetchSectionComments(sectionId)
                .then((response) => {
                  const sort = response.data.commentList.sort(function compare(a, b) {
                    const dateA = new Date(a.updatedAt);
                    const dateB = new Date(b.updatedAt);
                    return dateA - dateB;
                  });

                    commit('SET_SECTION_COMMENTS', sort)
                    commit('SET_LOADING', false)
                    resolve(response.data.commentList)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },
    async fetchDocumentComments({commit}, documentId) {
        return new Promise((resolve, reject) => {
            CommentService.fetchDocumentComments(documentId)
                .then((response) => {
                    commit('SET_DOCUMENT_COMMENTS', response.data.commentList)
                    resolve(response.data.commentList)
                })
                .catch((error) => {
                    commit('SET_ERROR', error, {root: true})
                    reject(error)
                })
        })
    },
};
