import {ApiService} from "./ApiService";

// eslint-disable-line no-unused-vars
class DocumentVariablesApiOperationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const DOCUMENTS_API_PATH = '/documents'


const DocumentVariableService = {
    handleErrorCases: (response) => {
        if (response.data.code) {
            throw new DocumentVariablesApiOperationError(response.data.code, response.data.message);
        }
        return response
    },
    setVariableValue: async (documentId, variableId, AddVariableRequest) => {
        const response = await ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/variables/${variableId}`, AddVariableRequest)
        DocumentVariableService.handleErrorCases(response)
        return response.data
    },

    getIssuedVariables: async (documentId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/variables/issued`)
        DocumentVariableService.handleErrorCases(response)
        return response.data
    },

    addVariable: async (documentId, AddVariableRequest) => {
        const response = await ApiService.post(`${DOCUMENTS_API_PATH}/${documentId}/variables`, AddVariableRequest)
        DocumentVariableService.handleErrorCases(response)
        return response.data
    },

    getVariable: async (documentId, variableId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/variables/${variableId}`)
        DocumentVariableService.handleErrorCases(response)
        return response.data
    },

    getVariableUsedInDocument: async (documentId, variableDefinitionId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/?varDef=${variableDefinitionId}`)
        DocumentVariableService.handleErrorCases(response)
        return response.data
    },

    removeVariable: async (documentId, variableId) => {
        const response = await ApiService.delete(`${DOCUMENTS_API_PATH}/${documentId}/variables/${variableId}`)
        DocumentVariableService.handleErrorCases(response)
        return response.data
    },

    updateVariable: async (documentId, UpdateVariableRequest) => {
        const response = await ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/variables`, UpdateVariableRequest)
        return this.handleErrorCases().then(() => response.data);
    },

}

export default DocumentVariableService;

export {DocumentVariablesApiOperationError};
