import {ApiService} from "./ApiService";

class GetSummaryError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const DashboardService = {
  getSummary: () => {
    return ApiService.get("/dashboard/summary")
  },
  getSummaryDocuments:()=>{
    return ApiService.get("/dashboard/documents/types")
  },
  getSummaryDocumentsCard:()=>{
    return ApiService.get("/dashboard/documents/current")
  },
  getReminderDocumentsCard() {
    return ApiService.get("/dashboard/reminders/current")
  }
}

export default DashboardService

export {GetSummaryError}
