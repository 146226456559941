import {CompanyService, CompanyListError, CompanyDeleteError} from "@/services/CompanyService";
import Vue from 'vue';

export default {
    list({commit}, payload) {
        return new Promise((resolve, reject) => {
            CompanyService.list(payload.workspaceId)
                .then((response) => {
                    if (response.data.code) {
                        throw new CompanyListError(response.data.code, response.data.message);
                    }
                    commit("COMPANY_LIST_SUCCESS", response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    create({commit}, request) {
        return new Promise((resolve, reject) => {
            CompanyService.create(request, request.workspaceId)
                .then((response) => {
                    if (response.data.code) {
                        throw CompanyListError(response.data.code, response.data.message)
                    } else {
                        //commit('ADD_COMPANY', response.data)
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async search({commit}, searchText) {
        try {
            commit("COMPANY_SEARCH_STARTED")
            const response = await CompanyService.search(searchText)
            commit("COMPANY_SEARCH_SUCCESS", response)
        } catch (e) {
            // payload.notify({
            //   time: 2500,
            //   title: "Error",
            //   text: e.message,
            //   iconPack: "feather",
            //   icon: "icon-alert-circle",
            //   color: "danger"
            // });
        }

        commit("COMPANY_SEARCH_ENDED")
    },

    async delete({commit}, payload) {
        let result = {}
        try {
            const response = await CompanyService.delete(payload.companyId)
            result = {
                success: true
            }
        } catch (e) {
            if (e instanceof CompanyDeleteError) {
                result = {
                    success: false,
                    error: e
                }

            }
        }
        // PEventBus.$emit('onCompanyDeleteCompleted', result)
    },

    update({commit}, request) {
        return new Promise((resolve, reject) => {
            CompanyService.update(request, request.companyId, request.workspaceId)
                .then((response) => {
                    if (response.data.code) {
                        throw CompanyListError(response.data.code, response.data.message)
                    } else {
                        //commit('UPDATE_COMPANY', response.data)
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    //  result.success=false
                    reject(error)
                })
        })
        // PEventBus.$emit('onCompanyUpdateCompleted', result)
    },
};
