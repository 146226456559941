import DashboardService, {GetSummaryError} from "../../services/DashboardService";

export default {
  getSummary({commit}) {
    return new Promise((resolve, reject) => {
      DashboardService.getSummary()
        .then((response) => {
          if (response.data.code) {
            throw GetSummaryError(response.data.code, response.data.message)
          }
          //console.log(response.data)
          commit('SUMMARY_LOAD_SUCCESS', response.data.summary)
          resolve(response)
        })
        .catch((error) => {
          // todo handle error case
          reject(error)
        })
    })
  },
    getSummaryDocumentsCard({commit}) {
        return new Promise((resolve, reject) => {
            DashboardService.getSummaryDocumentsCard()
                .then((response) => {
                    if (response.data.code) {
                        throw GetSummaryError(response.data.code, response.data.message)
                    }
                    commit('DOCUMENT_CURRENT_SUCCESS', response.data.documents)
                    //console.log(response.data,'DOCUMENT_CURRENT_SUCCESS')
                    resolve(response)
                })
                .catch((error) => {
                    // todo handle error case
                    reject(error)
                })
        })
    },
    getReminderDocumentsCard({commit}) {
        return new Promise((resolve, reject) => {
            DashboardService.getReminderDocumentsCard()
                .then((response) => {
                    if (response.data.code) {
                        throw GetSummaryError(response.data.code, response.data.message)
                    }
                    commit('DOCUMENT_REMINDER_SUCCESS', response.data.reminders)
                    console.log(response.data.reminders)
                    resolve(response)
                })
                .catch((error) => {
                    // todo handle error case
                    reject(error)
                })
        })
    },
    getLoadingDocuments({commit}) {
        return new Promise((resolve, reject) => {
            DashboardService.getSummaryDocuments()
                .then((response) => {
                    if (response.data.code) {
                        throw GetSummaryError(response.data.code, response.data.message)
                    }
                    commit('DOCUMENT_LOAD_SUCCESS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    // todo handle error case
                    reject(error)
                })
        })
    },
    getSharingDocuments({commit}) {
        return new Promise((resolve, reject) => {
            DashboardService.getSummaryDocuments()
                .then((response) => {
                    if (response.data.code) {
                        throw GetSummaryError(response.data.code, response.data.message)
                    }
                    commit('DOCUMENT_SHARE_SUCCESS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    // todo handle error case
                    reject(error)
                })
        })
    },
    getTypingDocuments({commit}) {
        return new Promise((resolve, reject) => {
            DashboardService.getSummaryDocuments()
                .then((response) => {
                    if (response.data.code) {
                        throw GetSummaryError(response.data.code, response.data.message)
                    }
                    commit('DOCUMENT_TYPE_SUCCESS', response.data)
                     //console.log(response.data)
                    resolve(response)
                })
                .catch((error) => {
                    // todo handle error case
                    reject(error)
                })
        })
    }
}
