import {ApiService} from "./ApiService";

// eslint-disable-line no-unused-vars
class DocumentLifecycleApiOperationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const DOCUMENTS_API_PATH = '/documents'

const DocumentLifecycleService = {

    handleErrorCases: (response) => {
        if (response.data.code) {
            throw new DocumentLifecycleApiOperationError(response.data.code, response.data.message);
        }
        return response
    },
    startApprovalProcess: (documentId) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/start-approval`)
    },
    stopApprovalProcess: (documentId) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/stop-approval`)
    },
    startSigningProcess: (payload) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${payload.documentId}/start-signing`, payload.request)
    },
    stopSigningProcess: (payload) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${payload.documentId}/stop-signing`, payload.request)
    },
    finishSigningProcess: (payload) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', payload.file);

        return ApiService.put(`${DOCUMENTS_API_PATH}/${payload.documentId}/finish-signing`, bodyFormData)
    },
    completeManually: (documentId) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/complete`)
    },
    archive: (documentId) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/archive`)
    },
    cancel: (documentId) => {
        return ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/cancel`)
    },
};

export default DocumentLifecycleService;

export {DocumentLifecycleApiOperationError};
