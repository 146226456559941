import Vue from 'vue';

export const PEventBus = new Vue();

const NotificationEventTypes = {
  ON_UPDATE_SUCCESS: 'on-update-success',
  ON_UPDATE_FAILED: 'on-update-success',
  ON_CREATE_SUCCESS: 'on-create-success',
  ON_CREATE_FAILED: 'on-create-failed',
  ON_FETCH_FAILED: 'on-fetch-failed',
  SUCCESS: 'on-operation-success',
  FAILED: 'on-operation-failed'
}

const ReminderEventTypes = {
  REMINDERS_LOADED: "REMINDERS_LOADED",
  REMINDER_CREATED: "REMINDER_CREATED",
  REMINDER_DELETED: "REMINDER_DELETED",
  REMINDER_UPDATED: "REMINDER_UPDATED",
  REMINDER_TEST_SENT: "REMINDER_TEST_SENT"
}

const GroupEventTypes = {
  GROUPS_LOADED: 'GROUPS_LOADED',
  GROUP_CREATED: "GROUP_CREATED",
  GROUP_CREATION_FAILED: "GROUP_CREATION_FAILED",
  GROUP_DELETED: "GROUP_DELETED",
  GROUP_UPDATED: "GROUP_UPDATED",
  GROUP_UPDATE_FAILED: "GROUP_UPDATE_FAILED"
}

const UserEventTypes = {
  USERS_LOADED: 'USERS_LOADED'
}

const OrganizationEventTypes = {
  ORGANIZATIONS_LOADED: 'ORGANIZATIONS_LOADED',
  ORGANIZATION_CREATED: "ORGANIZATION_CREATED",
  ORGANIZATION_CREATION_FAILED: "ORGANIZATION_CREATION_FAILED",
  ORGANIZATION_DELETED: "ORGANIZATION_DELETED",
  ORGANIZATION_UPDATED: "ORGANIZATION_UPDATED",
  ORGANIZATION_UPDATE_FAILED: "ORGANIZATION_UPDATE_FAILED",
  DEFAULT_ORGANIZATION_UPDATED: "DEFAULT_ORGANIZATION_UPDATED",

  ORGANIZATION_INVITATION_SEND_SUCCESSFULLY: "ORGANIZATION_INVITATION_SEND_SUCCESSFULLY",
  ORGANIZATION_INVITATION_SEND_FAILED: "ORGANIZATION_INVITATION_SEND_FAILED",
  ORGANIZATION_INVITATION_MAIL_ERROR: "ORGANIZATION_INVITATION_MAIL_ERROR",
  ORGANIZATION_AUTH_ERROR: "ORGANIZATION_AUTH_ERROR",
}

const VariableEventTypes = {
  VARIABLE_CREATED: 'VARIABLE_CREATED',
  VARIABLE_CREATION_FAILED: 'VARIABLE_CREATION_FAILED',
  VARIABLE_UPDATED: 'VARIABLE_UPDATED',
  VARIABLE_UPDATE_FAILED: 'VARIABLE_UPDATE_FAILED',
  VARIABLE_DELETED: 'VARIABLE_DELETED',
}

export {
  NotificationEventTypes,
  ReminderEventTypes,
  GroupEventTypes,
  UserEventTypes,
  OrganizationEventTypes,
  VariableEventTypes
}
