export default {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  ATTACHMENTS(state, payload) {
    state.attachments = payload
  },
  ATTACHMENTS_WERE_FETCHED(state, payload) {
    state.attachments = payload;
  }
};
