import i18n from "@/libs/i18n";

export default {
  SUMMARY_LOAD_SUCCESS(state, summary) {
    state.summary = summary
  },
  DOCUMENT_LOAD_SUCCESS(state, loadingDocuments) {
    state.loadingDocuments = loadingDocuments
  },
  DOCUMENT_SHARE_SUCCESS(state, sharingDocuments) {
    state.sharingDocuments = sharingDocuments
  },
  DOCUMENT_TYPE_SUCCESS(state, typingDocuments) {
    state.typingDocuments = {
      labels: typingDocuments.labels.map(t => i18n.t('documentTypes.'.concat(t))),
      frequencies: typingDocuments.frequencies.map(t => i18n.t('documentTypes.'.concat(t))),
      series: typingDocuments.series
    }
  },
  DOCUMENT_CURRENT_SUCCESS(state, currentDocument) {
    state.currentDocument = currentDocument
  },
  DOCUMENT_REMINDER_SUCCESS(state, reminderDocuments) {
    state.reminderDocuments = reminderDocuments
  }
};
