import {getScaleExtent} from "echarts/lib/coord/axisHelper";

const Constants = {
    globalEditorSettings: 'globalEditorSettings'
}
const GlobalEditorSettings = {
    fontFamily: 'Arial',
    fontSize: 14 // px
}

const IconUtils = {
    getIcon(type) {
        let icon = ''
        switch (type) {
            case 'SUB':
            case 'ART':
                icon = 'TypeIcon'
                break;
            case 'NO':
                icon = 'HashIcon'
                break;
            case 'ORG':
                icon = 'HomeIcon'
                break;
            case 'DATE':
                icon = 'CalendarIcon'
                break;
            case 'DURATION':
                icon = 'ClockIcon'
                break;
            case 'LOC':
                icon = 'MapPinIcon'
                break;
            case 'DOC_TYPE':
                icon = 'FileTextIcon'
                break;
            case 'QUANTITY':
                icon = 'PercentIcon'
                break;
            case 'MONEY':
            case 'IBAN':
                icon = 'DollarSignIcon'
                break;
            case 'EXPRESSION':
                icon = 'CodeIcon'
                break;
            default:
            // code block
        }
        return icon
    },
    getStatusColor(status) {
        switch (status) {
            case "NEW":
                return "primary"
            case "ACTIVE":
                return "success"
            case "SUSPENDED":
                return "warning"
            case "DRAFT":
                return "rgba(149, 175, 192,1.0)"
            case "FAILED":
                return "danger"
            case "ENDED":
                return ""
        }
    }
}

const QuillUtils = {
    applyDiff(editorInstance, diff) {
        editorInstance.setContents(diff.local.content, 'silent')
        let newContent = editorInstance.getContents()
        editorInstance.setContents(diff.remote.content, 'silent')
        let oldContent = editorInstance.getContents()
        let diffDelta = oldContent.diff(newContent);

        for (let i = 0; i < diffDelta.ops.length; i++) {
            let op = diffDelta.ops[i];
            // if the change was an insertion
            if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
                // color it green
                op.attributes = {
                    background: "#cce8cc",
                    color: "#003700"
                };
            }
            // if the change was a deletion
            if (Object.prototype.hasOwnProperty.call(op, 'delete')) {
                // keep the text
                op.retain = op.delete;
                delete op.delete;
                // but color it red and struckthrough
                op.attributes = {
                    background: "#e8cccc",
                    color: "#370000",
                    strike: true
                };
            }
        }
        let adjusted = oldContent.compose(diffDelta);

        return adjusted
    },
    composeVariables(sectionId, content, variables) {
        for (let i = 0; i < content.ops.length; i++) {
            let op = content.ops[i]
            if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
                let insert = op.insert
                if (Object.prototype.hasOwnProperty.call(insert, 'mention')) {
                    let mention = insert.mention
                    variables.forEach(variable => {
                        QuillUtils.composeVariable(sectionId, mention, variable.variableId, variable.userProcessedValue, variable.presentationMethod)
                    })
                }
            }
        }
        return content
    },
    setReadonlyParts(content) {
        for (let i = 0; i < content.ops.length; i++) {
            let op = content.ops[i]
            if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
                let insert = op.insert
                if (Object.prototype.hasOwnProperty.call(insert, 'heading')) {
                    let heading = insert.heading
                    heading['readonly'] = true
                }
                if (Object.prototype.hasOwnProperty.call(insert, 'mention')) {
                    let mention = insert.mention
                    mention['readonly'] = true
                }
            }
        }
    },
    unsetReadonlyParts(content) {
        for (let i = 0; i < content.ops.length; i++) {
            let op = content.ops[i]
            if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
                let insert = op.insert
                if (Object.prototype.hasOwnProperty.call(insert, 'heading')) {
                    let heading = insert.heading
                    heading['readonly'] = false
                }
                if (Object.prototype.hasOwnProperty.call(insert, 'mention')) {
                    let mention = insert.mention
                    mention['readonly'] = false
                }
            }
        }
    },
    composeVariable(sectionId, mention, variableId, value, presentationMethod) {
        if (variableId === mention.id) {
            if (value !== null && value.userValue !== null) {
                switch (presentationMethod) {
                    case 'SELECT_BOX':
                        if (value.userValue !== undefined && value.userValue.text !== undefined) {
                            mention['sectionId'] = sectionId
                            mention['userInput'] = value.userValue.text
                            mention['displayText'] = value.userValue.text
                        } else {
                            mention['sectionId'] = sectionId
                            mention['userInput'] = value.userValue
                            mention['displayText'] = value.userValue
                        }
                        return
                    case 'INPUT_TEXT' :
                        mention['sectionId'] = sectionId
                        mention['userInput'] = value.userValue
                        mention['displayText'] = value.userValue
                        return;
                    case 'CALENDAR':
                        mention['sectionId'] = sectionId
                        mention['userInput'] = value.userValue
                        mention['displayText'] = value.userValue
                        return
                }
            }
        }
    },
    reorderSectionHeadings(content, sectionHeading) {
        let levelSecondIterator = 0
        let levelThirdIterator = 0
        let levelForthIterator = 0

        for (let i = 0; i < content.ops.length; i++) {
            let op = content.ops[i]
            if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
                let insert = op.insert
                if (Object.prototype.hasOwnProperty.call(insert, 'papirNumbering')) {
                    const clause = insert.papirNumbering
                    let cursorLevelDeep = parseInt(clause.level)
                    if (cursorLevelDeep === 2) {
                        levelSecondIterator = levelSecondIterator + 1
                        let numbering = sectionHeading + '.' + levelSecondIterator + '. '

                        clause['numbering'] = numbering
                    } else if (3 === cursorLevelDeep) {
                        levelThirdIterator = levelThirdIterator + 1
                        let numbering = sectionHeading + '.' + levelSecondIterator + '.' + levelThirdIterator + '. '

                        clause['numbering'] = numbering
                    } else if (4 === cursorLevelDeep) {
                        levelForthIterator = levelForthIterator + 1
                        let numbering = sectionHeading + '.' + levelSecondIterator + '.' + levelThirdIterator + '.' + levelForthIterator + '. '

                        clause['numbering'] = numbering
                    }
                }
            }
        }
    },

    createEmptyHeading(level, numbering) {
        let heading = {
            level: level,
            numbering: numbering,
        }
        return heading
    }
}

class PossessiveSuffix {
    constructor() {
        this.conjunction = `'`;
    }

    word(vocable) {

        this.lastLetter = vocable.charAt(vocable.length - 1);
        this.vowels = 'aeıioöuü';
        this.possessiveSuffixes = ['nın', 'nin', 'nun', 'nün'];

        if (!this.vowels.includes(this.lastLetter)) {
            this.lastLetter = vocable.charAt(vocable.length - 2);
            this.possessiveSuffixes = ['ın', 'in', 'un', 'ün'];
        }

        this.possessiveSuffix = this.possessiveSuffixes[0];

        switch (this.lastLetter.toLocaleLowerCase()) {

            case 'a':
            case 'ı':
                this.possessiveSuffix = this.possessiveSuffixes[0];
                break;

            case 'e':
            case 'i':
                this.possessiveSuffix = this.possessiveSuffixes[1];
                break;

            case 'o':
            case 'u':
                this.possessiveSuffix = this.possessiveSuffixes[2];
                break;

            case 'ö':
            case 'ü':
                this.possessiveSuffix = this.possessiveSuffixes[3];
                break;

        }

        this.vocable = `${vocable}${this.conjunction}${this.possessiveSuffix}`;

        return this;

    }

    toLowerCase() {
        this.vocable = this.vocable.toLocaleLowerCase();
        return this;
    }

    toUpperCase() {
        this.vocable = this.vocable.toLocaleUpperCase();
        return this;
    }

    toCapitalize() {
        this.vocable = this.vocable.charAt(0).toLocaleUpperCase() + this.vocable.slice(1);
        return this;
    }

    with(word) {
        this.vocable += ` ${word}`;
        return this;
    }

    get() {
        return this.vocable;
    }
}

const MiscUtils = {
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}


const ColorUtils = {
    variableColorClasses: ['red', 'dark-red', 'orange', 'yellow', 'light-yellow', 'dark-yellow', 'lime-green', 'green', 'dark-green', 'mint', 'blue', 'dark-blue', 'gray', 'pink', 'purple']
}

export {IconUtils, QuillUtils, PossessiveSuffix, MiscUtils, Constants, GlobalEditorSettings, ColorUtils}
