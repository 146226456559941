export default {
  reminders: state => {
    return state.reminders
  },
  schedulePlans: state => {
    return state.schedulePlans
  },
  executionPlanByValue: state => (value) => {
    return state.schedulePlans.filter(r => r.value === value)[0]
  }
}
