import DocumentAttachmentService from "../../services/DocumentAttachmentService";

export default {
  addAttachment({commit}, payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      DocumentAttachmentService.add(payload)
        .then((response) => {
          commit("SET_LOADING", false)
          resolve(response.templates)
        })
        .catch(reason => {
          commit("SET_LOADING", false)
          reject(reason)
        })
    })
  },

  fetchAll({commit}, documentId) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      DocumentAttachmentService.fetchAll(documentId)
        .then((response) => {
          commit("ATTACHMENTS_WERE_FETCHED", response.attachments)
          commit("SET_LOADING", false)
          resolve(response.templates)
        })
        .catch(reason => {
          commit("SET_LOADING", false)
          reject(reason)
        })
    })
  },

  async fetchById({commit}, attachmentId) {
    return await DocumentAttachmentService.fetchById(attachmentId)
      .then(response => {
        const file = new Blob(
          [response.data], {type: response.headers['content-type']});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch(error => {});
  },

  async delete({commit}, attachmentId) {
    return await DocumentAttachmentService.deleteItem(attachmentId)
      .catch(error => {});
  }
};
