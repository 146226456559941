import state from './moduleCommentState'
import mutations from './moduleCommentMutations.js'
import actions from './moduleCommentActions.js'
import getters from './moduleCommentGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
