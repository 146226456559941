export default {
    SET_LOADING(state, loading) {
        state.listLoading = loading
    },
    SET_ONE_DOCUMENT_COMMENT(state, comment) {
        Object.keys(state.documentComments).forEach(key => {
            if (key === comment.commentId) {
                let commentGroup = state.documentComments[key]
                commentGroup.push(comment)
            }
        })
    },
    SET_ONE_SECTION_COMMENT(state, comment) {
        Object.keys(state.sectionComments).forEach(key => {
            if (key === comment.commentId) {
                let commentGroup = state.sectionComments[key]
                commentGroup.push(comment)
            }
        })
    },
    SET_DOCUMENT_COMMENTS(state, list) {
        state.documentComments = list
    },
    SET_SECTION_COMMENTS(state, list) {
        state.sectionComments = list
    }
}
