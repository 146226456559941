import {ApiService} from "./ApiService";

class AttachmentCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const DocumentAttachmentService = {
  add: async (payload) => {
    const bodyFormData = new FormData();
    bodyFormData.append('documentId', payload.documentId);
    bodyFormData.append('file', payload.file);
    bodyFormData.append('title', payload.title);

    const response = await ApiService.formData(`/documents/attachments/upload`, bodyFormData)

    if (response.data.code) {
      throw new AttachmentCrudError(response.data.code, response.data.message);
    }

    return response.data;
  },

  fetchAll: async (documentId) => {
    const response = await ApiService.get(`/documents/${documentId}/attachments`);

    if (response.data.code) {
      throw new AttachmentCrudError(response.data.code, response.data.message);
    }

    return response.data;
  },

  fetchById: async (attachmentId) => {
    const response = await ApiService.getFile(`/attachments/${attachmentId}`);
    if (response.data.code) {
      throw new AttachmentCrudError(response.data.code, response.data.message);
    }
    return response;
  },

  deleteItem: async (attachmentId) => {
    const response = await ApiService.delete(`/attachments/${attachmentId}`);
    if (response.data.code) {
      throw new AttachmentCrudError(response.data.code, response.data.message);
    }
    return response;
  }
};

export default DocumentAttachmentService;
