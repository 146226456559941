import {ApiService} from "./ApiService";

class ReminderCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const REMINDER_API = "/reminders"

const ReminderService = {
  fetchAll: async (documentId) => {
    const response = await ApiService.get("/documents/" + documentId + REMINDER_API)
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
    return response
  },

  create: async (payload, documentId) => {
    const response = await ApiService.post("/documents/" + documentId + REMINDER_API, payload)
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
    return response
  },

  update: async (payload, reminderId) => {
    const response = await ApiService.put(REMINDER_API + `/${reminderId}`, payload)
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
    return response
  },

  delete: async (reminderId) => {
    const response = await ApiService.delete(REMINDER_API + `/${reminderId}`)
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
  },

  start: async (reminderId) => {
    const response = await ApiService.post(REMINDER_API + "/" + reminderId + "/start", {})
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
    return response
  },

  pause: async (reminderId) => {
    const response = await ApiService.post(REMINDER_API + "/" + reminderId + "/pause", {})
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
    return response
  },

  sendTestMail: async (request) => {
    const response = await ApiService.post(REMINDER_API + "/test", request)
    if (response.data.code) {
      throw new ReminderCrudError(response.data.code, response.data.message)
    }
    return response
  },
};

export default ReminderService;
