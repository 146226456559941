import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import apps from './routes/apps'
import documents from "./routes/documents";
import assignments from "./routes/assignments";
import templates from "./routes/templates";
//import teams from "./routes/teams";
import organizations from "./routes/organizations";
import companies from "./routes/companies";
import variables from "./routes/variables";
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {path: '/', redirect: {name: 'dashboard'}},
    ...apps,
    ...documents,
    ...assignments,
    ...templates,
    //...teams,
    ...organizations,
    ...variables,
    ...dashboard,
    ...companies,
    ...pages,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({name: 'Auth-login'})
    }
    // If logged in => not authorized
    return next({name: 'misc-not-authorized'})
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  next()
})

export default router
