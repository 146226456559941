import { TokenService, UserRepository } from "@/services/StorageService";

export default {
  LOGIN_SUCCESS({ state }, payload) {
    UserRepository.saveAsJson(payload.user);
    TokenService.saveToken(payload.token);
  },

  INVITED_USER_EMAIL(state, email) {
    state.invitedUserEmail = email
  }
}
