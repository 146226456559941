import GroupService from "../../services/GroupService";
import {GroupEventTypes, PEventBus} from "@/services/PEventBus";

export default {
  async fetchAll({commit}) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      GroupService.fetchAll()
        .then((response) => {
          if (response.data.code) {
            commit('SET_ERROR', response.data, {root: true})
          } else {
            commit('GROUPS_FETCHED', response.data.groups)
          }
          commit("SET_LOADING", false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('SET_ERROR', error, {root: true})
          commit("SET_LOADING", false)
          reject(error)
        })
    });
  },

  async fetchById({commit}, groupId) {
    // todo incomplete
    await GroupService.fetchById(groupId)
  },

  async create({commit}, payload) {
    return await GroupService.create(payload.request)
  },

  async update({commit}, payload) {
    return await GroupService.update(payload.groupId, payload.request)
  },

  async delete({commit}, groupId) {
    await GroupService.delete(groupId)
    PEventBus.$emit(GroupEventTypes.GROUP_DELETED)
  },

  async addUser({commit}, payload) {
    return await GroupService.addUser(payload.groupId, payload.request)
  },

  async editUserGroup({commit}, payload) {
    return await GroupService.editUserGroup(payload.groupId, payload.userId, payload.request)
  },

  async removeUser({commit}, payload) {
    try {
      await GroupService.removeUser(payload.groupId, payload.userId)
    } catch (e) {
      return Promise.reject(e)
    }
  }
};
