export default {
  ORGANIZATIONS_FETCHED(state, data) {
    state.myWorkspaces = data.myWorkspaces
    state.invitedWorkspaces = data.invitedWorkspaces
    state.loading = false
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SELECTED_ORGANIZATION(state, organization) {
    state.organization = organization
  },
  SET_ORGANIZATION_MEMBERS(state, members) {
    state.organizationMembers = members
  },
  SET_WORKSPACE_GROUPS(state, groups) {
    state.groups = groups
  },
  SET_INVITED_MEMBERS(state, invitedMembers) {
    state.invitedMembers = invitedMembers
  }
}
