import {AuthenticationError, UserAccountService} from "@/services/UserAccountService";
import {TokenService, UserRepository} from "@/services/StorageService";
import useJwt from '@/auth/jwt/useJwt'
import ability from "@/libs/acl/ability";
import {locales} from "@/libs/locales";
import {ApiService} from "@/services/ApiService";

export default {
  login({commit}, payload) {
    return new Promise((resolve, reject) => {
      useJwt.login(payload)
        .then((response) => {
          // USER_NOT_FOUND
          if (response.data.code === 1005) {
            throw new AuthenticationError(response.data.code, response.data.exceptionMessage)
          }
          const userData = {
            id: 1,
            userId: response.data.userId,
            fullName: response.data.username,
            username: response.data.email,
            // eslint-disable-next-line global-require
            avatar: null,
            initials: response.data.initials,
            email: response.data.email,
            role: 'admin',
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
            preferredLanguage: response.data.preferredLanguage
          }
          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))

          ability.update(userData.ability)

          //commit("LOGIN_SUCCESS", {token: response.data.accessToken, user: user});
          //commit("UPDATE_AUTHENTICATED_USER", user, {root: true});

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  registerUser({commit}, payload) {
    return new Promise(((resolve, reject) => {
      useJwt.register(payload)
        .then(response => {

          if (response.data.code === 1003) {
            throw new AuthenticationError(response.data.code, response.data.exceptionMessage)
          }
          // const userData = {
          //   id: response.data.id,
          //   fullName: response.data.fullName,
          //   username: response.data.fullName,
          //   // eslint-disable-next-line global-require
          //   avatar: require(''),
          //   email: response.data.email,
          //   role: 'admin',
          //   ability: [
          //     {
          //       action: 'manage',
          //       subject: 'all',
          //     },
          //   ]
          // }
          // useJwt.setToken(response.data.accessToken)
          // useJwt.setRefreshToken(response.data.refreshToken)
          // localStorage.setItem('userData', JSON.stringify(userData))
          // ability.update(userData.ability)


          resolve(response)
        }).catch(error => {
        reject(error)
      })
    }))

  },

  async completeInvitation({commit}, payload) {
    return new Promise((resolve, reject) => {
      useJwt.invitationCompleted(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  async completeRegistration({commit}, payload) {
    return new Promise((resolve, reject) => {
      useJwt.registrationCompleted(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  async fetchUserData({commit}, payload) {
    return new Promise((resolve, reject) => {
      useJwt.fetchUserData(payload)
        .then((response) => {
          commit("INVITED_USER_EMAIL", response.data.email)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  updateUser({commit}) {
    let user = UserRepository.getCurrentUser();
    if (user) {
      commit("UPDATE_AUTHENTICATED_USER", user, {root: true});
    }
  },

  async forgotPassword({commit}, payload) {
    return await UserAccountService.forgotPassword(payload);
  },

  async resetPassword({commit}, payload) {
    return await UserAccountService.resetPassword(payload);
  },
}
;
