import {ApiService} from "./ApiService";

const LABEL_API = "/labels"

class LabelCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const LabelService = {
  query: async (queryText) => {
    const response = await ApiService.get(LABEL_API + '?query=' + queryText)
    if (response.data.code) {
      throw new LabelCrudError(response.data.code, response.data.message)
    }
    return response
  },
  loadAll: async () => {
    const response = await ApiService.get(LABEL_API)
    if (response.data.code) {
      throw new LabelCrudError(response.data.code, response.data.message)
    }
    return response
  },
  addToDocument: async (documentId, request) => {
    const response = await ApiService.post('/documents/' + documentId + '/labels', request)
    if (response.data.code) {
      throw new LabelCrudError(response.data.code, response.data.message)
    }
    return response
  },
  remove: async (documentId, label) => {
    const response = await ApiService.delete('/documents/' + documentId + '/labels/' + label)
    if (response.data.code) {
      throw new LabelCrudError(response.data.code, response.data.message)
    }
    return response
  }
};

export default LabelService
