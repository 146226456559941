export default {
    contractId: null,
    authenticating: false,
    authenticationErrorCode: 0,
    authenticationError: "",
    myDocumentList: [],
    sharedDocumentList: [],
    archivedDocumentList: [],
    toBeUploadedFileList: [],
    listLoading: false,
    variablesLoading: false,
    sections: [],
    variableClickData: {
        clicked: false,
    },
    focusedSectionId: null,
    unsavedSectionIds: [],
    variableList: [],
    variableBlotList: [],
    templatesLoading: false,
    templateList: [],
    ruleList: [],
    selectedSectionStatus: {
        sectionId: null,
        unsaved: false,
        autosaving: false,
        formats: null,
    },
    statistics: {},
    document: {
        id: null,
        title: 'Adsız doküman',
        code: null,
        createdAt: null,
        status: null,
        templateId: null,
        attributes: null,
        rules: null,
        documentType: null,
        owner: {
            id: null,
            fullName: null
        },
        labels: [],
        groups: [],
        htmlContent: null,
    },
    emptyDocument: {
        id: null,
        title: 'Adsız doküman',
        code: null,
        createdAt: null,
        status: null,
        templateId: null,
        attributes: null,
        rules: null,
        documentType: null,
        owner: {
            id: null,
            fullName: null
        },
        labels: [],
        groups: []
    },
    documentTypes: [],
    documentStatuses: [],
    approverSelection: false,
    selectedSectionId: null,
    queryDocResult: [],
    relatedDocuments: [],
    reminders: [],
    possibleDocumentSigners: [],
    history: []
}
