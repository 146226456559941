import {ApiService} from "./ApiService";
import {TokenService} from "./StorageService";

class AuthenticationError extends Error {
  constructor(errorCode = 1005, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }

  toString() {
    return this.message
  }
}

class UserListError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserAccountService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  login: async (email, password) => {
    const requestData = {
      method: "post",
      url: "/login",
      data: {
        username: email,
        password: password
      },
      auth: {
        "Content-Type": "application/json"
      }
    };

    const response = await ApiService.customRequest(requestData);

    if (response.data.code) {
      throw new AuthenticationError(response.data.code, response.data.message);
    }

    TokenService.saveToken(response.data.accessToken);
    ApiService.setHeader();

    return response.data;
  },

  registerUser: async payload => {
    const requestData = {
      method: "post",
      url: "/register",
      data: {
        email: payload.userDetails.email,
        password: payload.userDetails.password,
        firstName: payload.userDetails.username,
        lastName: payload.userDetails.lastname
      },
      auth: {
        "Content-Type": "application/json"
      }
    };

    const response = await ApiService.customRequest(requestData);

    if (response.data.code) {
      throw new AuthenticationError(response.data.code, response.data.message);
    }

    TokenService.saveToken(response.data.accessToken);
    ApiService.setHeader();

    return response.data
  },

  fetchAll: async () => {
    return await ApiService.get("/users/all")
  },

  invite: async (email) => {
    return await ApiService.post('/invite/init', {email: email});
  },

  query: async (payload) => {
    const response = await ApiService.get(`/users/workspaces/${payload.workspaceId}?query=${payload.query}`)
    if (response.data.code) {
      throw new UserListError(response.data.code, response.data.message)
    }
    return response
  },

  updatePreferredLanguage: async (language) => {
    return await ApiService.put(`users/preferences?language=${language}`)
  },

  forgotPassword: async (payload) => {
    return await ApiService.post('/forgot-password', payload);
  },

  resetPassword: async (payload) => {
    return await ApiService.post('/reset-password', payload);
  }
};

export default UserAccountService;

export {UserAccountService, AuthenticationError, UserListError};
