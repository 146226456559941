import { TokenService } from "../../services/StorageService";

export default {
  authenticating: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: "",
  isUserLoggedIn: () => {
      return TokenService.getToken()
  },
  invitedUserEmail: null
}