import mock from './mock'

// JWT
import './jwt'

// pages
import './data/pages/faq-data'
import './data/pages/knowledge-base'

mock.onAny().passThrough() // forwards the matched request over network
