export const locales = [
  {
    locale: 'en',
    img: require('@/assets/images/flags/en.png'),
    name: 'English',
    value: 'EN'
  },
  {
    locale: 'tr',
    img: require('@/assets/images/flags/tr.png'),
    name: 'Türkçe',
    value: 'TR'
  },
  // {
  //   locale: 'fr',
  //   img: require('@/assets/images/flags/fr.png'),
  //   name: 'French',
  // },
  // {
  //   locale: 'de',
  //   img: require('@/assets/images/flags/de.png'),
  //   name: 'German',
  // },
  // {
  //   locale: 'pt',
  //   img: require('@/assets/images/flags/pt.png'),
  //   name: 'Portuguese',
  // },
]