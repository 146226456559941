import Vue from 'vue'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/material_blue.css'
import flatPickr from 'vue-flatpickr-component'

import language from 'flatpickr/dist/l10n/tr'

flatpickr.setDefaults({
  altFormat: 'j M. Y',
  dateFormat: 'Y-m-d',
  locale: language.tr
});
Vue.use(flatPickr);
