import state from './moduleAttachmentState.js'
import mutations from './moduleAttachmentMutations.js'
import actions from './moduleAttachmentActions.js'
import getters from './moduleAttachmentGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
