export default {
  listLoading: false,
  sections:[],
  definedVariables: [],
  commentList: [],
  sectionVersionsLoading: false,
  versionList: [],
  sectionApprovers: [],
  selectedVersion: null,
  secondSelectedVersion: null,
  requesterAccessLevel: null
}
