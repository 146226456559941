import state from './moduleReviewState.js'
import mutations from './moduleReviewMutations.js'
import actions from './moduleReviewActions.js'
import getters from './moduleReviewGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
