import {ApiService} from "./ApiService";

class GroupNotFoundError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class GroupsCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class GroupListLoadError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const GroupService = {
  fetchAll: () => {
    return ApiService.get("/groups")
  },

  fetchUsersByGroupId: async (groupId) => {
    const response = await ApiService.get("/groups/" + groupId + "/users")
    if (response.data.code) {
      throw new GroupListLoadError(response.data.code, response.data.message)
    }
    return response
  },

  fetchById: async (groupId) => {
    const response = await ApiService.get("/groups/" + groupId)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response
  },
  create: async (request) => {
    const response = await ApiService.post("/groups", request)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response.data
  },

  update: async (groupId, request) => {
    const response = await ApiService.put("/groups/" + groupId, request)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  delete: async (groupId) => {
    const response = await ApiService.delete("/groups/" + groupId)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  addUser: async (groupId, request) => {
    const response = await ApiService.post("/groups/" + groupId + "/users", request)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  editUserGroup: async (groupId, userId, request) => {
    const response = await ApiService.put("/groups/" + groupId + "/users/" + userId, request)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response
  },

  removeUser: async (groupId, userId) => {
    const response = await ApiService.delete("/groups/" + groupId + "/users/" + userId)
    if (response.data.code) {
      throw new GroupsCrudError(response.data.code, response.data.message)
    }
    return response
  }
}

export default GroupService

export {GroupNotFoundError, GroupListLoadError, GroupsCrudError}
