import state from './moduleDashboardState'
import mutations from './moduleDashboardMutations.js'
import actions from './moduleDashboardActions.js'
import getters from './moduleDashboardGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
