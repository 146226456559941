import {ContractRepository} from '../../services/StorageService'

export default {
  getCurrentContractId:() => {
    return ContractRepository.getCurrentContractId()
  },

  getContractList: state => {
    return state.contractList
  },

  getRuleList: state => {
    return state.ruleList
  },
  getDocumentTypes: state => {
    return state.documentTypes
  },
  getDocumentStatuses: state => {
    return state.documentStatuses
  }
}
