export default {
  reminders: [],
  schedulePlans: [ // todo refactor
    {text: "Her saat başında 1 defa", value: "EVERY_HOUR"},
    {text: "Her gün başında 1 defa", value: "EVERY_DAY"},
    {text: "Her 3 günde 1 defa", value: "EVERY_3_DAY"},
    {text: "Her hafta 1 defa", value: "EVERY_WEEK"},
    {text: "2 haftada bir defa", value: "EVERY_2_WEEK"}
  ],
}
