import {ApiService} from "./ApiService";

// eslint-disable-line no-unused-vars
class DocumentsApiOperationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const DOCUMENTS_API_PATH = '/documents'

const DocumentService = {

    handleErrorCases: (response) => {
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response
    },

    initiate: (request) => {
        return ApiService.post(`${DOCUMENTS_API_PATH}/initiate`, request)
    },

    create: (request) => {
        return ApiService.post(DOCUMENTS_API_PATH, request)
    },

    createFromTemplate: (request) => {
        return ApiService.post(`${DOCUMENTS_API_PATH}/create`, request)
    },

    createWithContent: (request) => {
        return ApiService.post(`${DOCUMENTS_API_PATH}/create-with-content`, request)
    },

    update: async (documentId, title) => {
        const request = {
            documentName: title,
            headers: {
                "Content-Type": "application/json"
            }
        }
        const response = await ApiService.patch(DOCUMENTS_API_PATH + "/" + documentId, request)
        DocumentService.handleErrorCases(response)
        return response.data
    },

    persistAllContent: async (documentId, title) => {
        const request = {
            documentName: title,
            headers: {
                "Content-Type": "application/json"
            }
        }
        const response = await ApiService.put(DOCUMENTS_API_PATH + "/" + documentId, request)
        return this.handleErrorCases().then(() => response.data);
    },

    fetchDocumentStatistics: (documentId) => {
      return ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/badges`)
    },

    updateDocumentType: async (documentId, documentType) => {
        const request = {
            documentType: documentType,
        }
        const response = await ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/document-type`, request)
        DocumentService.handleErrorCases(response)
        return response.data;
    },

    upload: async (data, config) => {
        const response = await ApiService.upload(DOCUMENTS_API_PATH + "/upload", data, config)
        DocumentService.handleErrorCases(response)
        return response.data
    },

    importWord: async (data, config) => {
        const response = await ApiService.upload('/documents/import/upload', data, config)
        DocumentService.handleErrorCases(response)
        return response.data
    },

    convertWord: async (data, config) => {
        const response = await ApiService.upload('/documents/convert-from-doc', data, config)
        DocumentService.handleErrorCases(response)
        return response.data
    },

    toggleNumbering: async (documentId) => {
        const response = await ApiService.put(`${DOCUMENTS_API_PATH}/${documentId}/numbering`, {})
        DocumentService.handleErrorCases(response)
        return response.data
    },

    analyze: async (payload) => {
        const response = await ApiService.put(DOCUMENTS_API_PATH + "/analyze", payload)
        return this.handleErrorCases().then(() => response.data);
    },

    deleteDocument: async (documentId) => {
        return await ApiService.delete(DOCUMENTS_API_PATH + "/" + documentId);
    },

    archiveDocument: async (documentId) => {
        return await ApiService.put(DOCUMENTS_API_PATH + "/" + documentId + "/archive").then(response => {
            response.data;
        })
    },

    load: (documentId) => {
        return ApiService.get(DOCUMENTS_API_PATH + "/" + documentId)
    },

    loadHtmlContent: (documentId) => {
        return ApiService.get(DOCUMENTS_API_PATH + "/" + documentId + "/html")
    },

    loadApprovers: (documentId) => {
        return ApiService.get(DOCUMENTS_API_PATH + "/" + documentId + "/approvers")
    },

    loadApproverActions: (sectionId) => {
        return ApiService.get( "/sections/" + sectionId + "/approvers/actions")
    },

    bulkApproveSections: (documentId) => {
        return ApiService.put(DOCUMENTS_API_PATH + "/" + documentId + "/approve/bulk", {})
    },

    loadMyDocuments: () => {
        return ApiService.get(`${DOCUMENTS_API_PATH}`)
    },

    loadSharedDocuments: () => {
        return ApiService.get(`/shared/documents`)
    },

    loadArchivedDocuments: (queryParams) => {
        return ApiService.get(`/archived/documents?q=${queryParams.q}&page=${queryParams.page}`)
    },

    applyTemplate: async (documentId, templateId) => {
        const request = {
            templateId: templateId
        };
        const response = await ApiService.post(DOCUMENTS_API_PATH + "/" + documentId + "/apply-template", request)
        return this.handleErrorCases().then(() => response.data);
    },

    getDocumentTypes: async () => {
        const response = await ApiService.get("/document-types");
        return response.data;
    },

    getDocumentStatuses: async () => {
        const response = await ApiService.get("/document-statuses");
        return response.data;
    },

    queryDocuments: (payload) => {
        return ApiService.post("/documents/query", payload);
    },
    addGroup: async (payload) => {
        return await ApiService.post(`${DOCUMENTS_API_PATH}/${payload.documentId}/sections/${payload.sectionId}/groups`, payload.request)
    },
    removeGroup: async (documentId, groupId) => {
        return await ApiService.delete(DOCUMENTS_API_PATH + "/" + documentId + '/groups/' + groupId)
    },

    filterSectionsByGroups: async (documentId, request) => {
        return await ApiService.patch(`${DOCUMENTS_API_PATH}/${documentId}/sections/filters/groups`, request)
    },

    downloadAsPdfById: async (attachmentId) => {
        const response = await ApiService.getFile(`${DOCUMENTS_API_PATH}/${attachmentId}/download`);
        if (response.data.type === 'application/json' || response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    downloadDocxById: async (attachmentId) => {
        const response = await ApiService.getFile(`${DOCUMENTS_API_PATH}/${attachmentId}/download/docx`);
        if (response.data.type === 'application/json' || response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    downloadArchivedFile: async (fileId) => {
        const response = await ApiService.getFile(`/archived-files/${fileId}/download`);
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    exportById: async (attachmentId) => {
        const response = await ApiService.getFile(`${DOCUMENTS_API_PATH}/${attachmentId}/export`);
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    importFromExportedFormat: async (data, config) => {
        const response = await ApiService.upload(`/document-import`, data, config)
        DocumentService.handleErrorCases(response)
        return response.data
    },

    simpleQuery: async (payload) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${payload.documentId}/link?query=` + payload.query)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    getDocumentRelations: async (documentId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/relations`)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    getDocumentReminders: async (documentId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/reminders`)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    getDocumentSignerInfo: async (documentId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/signers`)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    removeDocumentRelation: async (payload) => {
        const response = await ApiService.deleteWithData(`${DOCUMENTS_API_PATH}/${payload.documentId}/relations`, payload.request)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    addToDocumentLink: async (payload) => {
        const response = await ApiService.post(`${DOCUMENTS_API_PATH}/${payload.documentId}/link`,payload.request)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    setDocumentSigner: async (payload) => {
        const response = await ApiService.post(`${DOCUMENTS_API_PATH}/${payload.documentId}/signers`,payload.request)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    },

    getDocumentHistory: async (documentId) => {
        const response = await ApiService.get(`${DOCUMENTS_API_PATH}/${documentId}/history`)
        if (response.data.code) {
            throw new DocumentsApiOperationError(response.data.code, response.data.message);
        }
        return response;
    }
};

export default DocumentService;

export {DocumentsApiOperationError};
