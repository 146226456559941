import state from './moduleLibraryState.js'
import mutations from './moduleLibraryMutations.js'
import actions from './moduleLibraryActions.js'
import getters from './moduleLibraryGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
