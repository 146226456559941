export default [
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('@/views/Assignment/Inbox/Inbox.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'inbox-page',
    },
  },
  {
    path: '/inbox/sent',
    name: 'inbox-sent',
    component: () => import('@/views/Assignment/Sent/Sent.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'inbox-page',
    },
  },
  {
    path: '/inbox/closed',
    name: 'inbox-closed',
    component: () => import('@/views/Assignment/Closed/Closed.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'inbox-page',
    },
  }
]