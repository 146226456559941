import {ApiService} from './ApiService'


class CommentOperationsError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const CommentService = {
    postComment: async (request) => {
        const response = await ApiService.post(`/comments`, request)
        if (response.data.code) {
            throw new CommentOperationsError(response.data.code, response.data.message)
        }
        return response;
    },

    editComment: async (sectionId, commentId, request) => {
        const response = await ApiService.put(`/comments/${commentId}`, request)
        if (response.data.code) {
            throw new CommentOperationsError(response.data.code, response.data.message)
        }
        return response;
    },

    deleteComment: async (sectionId, commentId) => {
        const response = await ApiService.delete(`/comments/${commentId}`)
        if (response.data.code) {
            throw new CommentOperationsError(response.data.code, response.data.message)
        }
        return response;
    },

    resolveComment: async (sectionId, commentId) => {
        const response = await ApiService.put(`/comments/${commentId}/resolve`, {})
        if (response.data.code) {
            throw new CommentOperationsError(response.data.code, response.data.message)
        }
        return response;
    },

    fetchDocumentComments: async (documentId) => {
        const response = await ApiService.get(`/documents/${documentId}/comments`)
        if (response.data.code) {
            throw new CommentOperationsError(response.data.code, response.data.message)
        }
        return response;
    },
    fetchSectionComments: async (sectionId) => {
        const response = await ApiService.get(`/sections/${sectionId}/comments`)
        if (response.data.code) {
            throw new CommentOperationsError(response.data.code, response.data.message)
        }
        return response;
    },
};

export default CommentService;

export {
    CommentOperationsError
}

