export default {
  loading: state => {
    return state.loading
  },
  templates: state => {
    return state.itemList;
  },

  getTemplateById: state => {
    state.itemList.forEach(value => {
    })
  },

  attributes: state => {
    return state.attributes
  },
  recommendedAttributes: state => {
    return state.attributes.filter(attribute => attribute.required).reduce((result, attribute) => {
      result[attribute['uuid']] = true;
      return result;
    }, {});
  },
  documentTypes: state => {
    return state.documentTypes
  }
}
