export default {
  getContractsSummaryCard: state => {
    return state.summary.filter(value => value.type == 'CONTRACT_COUNT_CARD')
  },
  getRulesSummaryCard: state => {
    return state.summary.filter(value => value.type == 'RULE_COUNT_CARD')
  },
  getTicketsSummaryCard: state => {
    return state.summary.filter(value => value.type == 'TICKET_COUNT_CARD')
  },
  getAttributesSummaryCard: state => {
    return state.summary.filter(value => value.type == 'ATTR_COUNT_CARD')
  },
  getLoadingDocuments:state=>{
    return state.documents.series;
  },
  getSharingDocuments:state=>{
    return state.documents.series;
  },
  getTypingDocuments:state=> {
    return state.documents.series;
  },
  getSummaryDocumentsCard:state=>{
    return state.currentDocument;
  },
  getReminderDocumentsCard:state=>{
    return state.currentDocument;
  },

}
