import {ApiService} from "./ApiService";


class AssignmentServiceCrudError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const AssignmentService = {
  fetchInbox: async (payload) => {
    return await ApiService.get(`/assignments/inbox?q=${payload.q}`)
  },
  fetchSent: async (payload) => {
    return await ApiService.get(`/assignments/sent?q=${payload.q}`)
  },
  fetchClosed: async (payload) => {
    return await ApiService.get(`/assignments/closed?q=${payload.q}`)
  }
}

export default AssignmentService

export {AssignmentServiceCrudError}
